import { AxiosIndex } from '@/services/config.js';
import qs from 'qs';
import router from '@/router/index.js';

AxiosIndex.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push('/');
    }
    return Promise.reject(error);
  },
);

export default {
  evtremuns: {
    get: (filter) => {
      return AxiosIndex.post('evtremuns/salary-mass-tochart', filter);
    },
  },
  indexProcess: {
    getAll: () => {
      return AxiosIndex.get('index-process');
    },

    getAllByUser: (userLogged) => {
      return AxiosIndex.get(`index-process/${userLogged}`);
    },

    startIndex: () => {
      return AxiosIndex.get('index-process/start-index');
    },
    getCategories: () => {
      return AxiosIndex.get('index-process/count-categories');
    },
  },
};
