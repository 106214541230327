export const situationType = {
  ceded: 'Cedido',
  inactive: 'Não cedido',
  vacation: 'Não cedido',
  active: 'Não cedido',
};

export function getSituationType(value) {
  return situationType[value];
}
