import AdminService from '@/services/adminServices.js';
import UserService from '@/services/userService.js';
import sortBy from 'lodash/sortBy';
import { definePaginationQuery, isSamePagination } from '@/utils/pagination';
export default {
  namespaced: true,

  state: {
    users: [],
    selectedUserEstablishments: [],
    loadingSelectedUserEstablishments: false,
    loadingUsers: true,
    selectedUserId: '',
    pagination: {
      itemsPerPage: 10,

      page: 1,

      sortBy: [],
    },
  },

  getters: {
    getPagination(state) {
      return state.pagination;
    },
  },

  actions: {
    async getEstablishments({ dispatch, commit }, userId) {
      commit('SET_USER_SELECTED_ID', userId);
      await dispatch('getSelectedUserEstablishment', userId);
    },

    async getSelectedUserEstablishment({ commit }, userId) {
      try {
        commit('SET_LOADING_SELECTED_USER_ESTABLISHMENTS', true);
        let { data } = await AdminService.userEstablishment.get(userId);
        let userEstablishments = data.map((item) => item.establishment);
        userEstablishments = sortBy(userEstablishments, ['socialName', 'stateCode', 'city']);
        commit('SET_SELECTED_USER_ESTABLISHMENTS', userEstablishments);
      } catch (e) {
        console.error('Error fetching user establishment');
      } finally {
        commit('SET_LOADING_SELECTED_USER_ESTABLISHMENTS', false);
      }
    },
    setPagination({ commit, state, dispatch }, payload) {
      let notSamePagination = !isSamePagination(payload.pagination, state.pagination);

      commit('SET_PAGINATION', payload.pagination);

      if (notSamePagination) {
        dispatch('fetchCboOverview');
      }
    },

    async getUsers({ commit, state }) {
      // let paginationObject = state.pagination;
      // let pagination = definePaginationQuery(paginationObject);
      try {
        // let { data } = await UserService.find(pagination);
        let { data } = await UserService.find();

        let listado = [];
        let keys = [];

        Object.entries(data).forEach(([key, entries]) => {
          keys.push(key);
          listado.push({
            id: entries.id,
            email: entries.emailAddress,
            username: entries.username,
            firstName: entries.firstName,
            lastName: entries.lastName,
            authorities: entries.authorities,
            establishment: entries.establishment,
          });
        });

        if (listado.length > 0) {
          commit('SET_USERS', listado);
          commit('SET_LOADING_USERS', false);
        } else {
          commit('SET_LOADING_USERS', true);
        }
      } catch (e) {
        commit('SET_LOADING_USERS', false);
      }
    },

    async saveUser({ dispatch }, payload) {
      await UserService.save(payload);
      dispatch('getUsers');
    },
  },

  mutations: {
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_USER_SELECTED_ID(state, payload) {
      state.selectedUserId = payload;
    },
    SET_SELECTED_USER_ESTABLISHMENTS(state, payload) {
      state.selectedUserEstablishments = payload;
    },
    SET_LOADING_SELECTED_USER_ESTABLISHMENTS(state, payload) {
      state.loadingSelectedUserEstablishments = payload;
    },
    SET_LOADING_USERS(state, payload) {
      state.loadingUsers = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
  },
};
