import eSocialService from '@/services/eSocialService.js';
import { cloneDeep } from 'lodash';

import { definePaginationQuery, isSamePagination } from '@/utils/pagination';

function defaultPagination() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}
export default {
  namespaced: true,

  state: {
    loadingCollaborators: false,
    paginationCollaborators: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },
    totalCollaborators: 0,
    filterCollaborators: {},
    scheduleReport: false,
    collaborators: [],
  },
  actions: {
    async init({ dispatch, state }) {
      state.filterCollaborators.nameCpfMatriculationRg = null;
      await dispatch('fetchCollaborators');
    },

    changeFilter({ commit, dispatch, state }, { prop, value }) {
      commit('SET_LOADING_COLLABORATORS', true);
      const filter = cloneDeep(state.filterCollaborators);
      filter[prop] = value;
      commit('SET_FILTER_COLLABORATORS', filter);

      dispatch('setPagination', defaultPagination());
    },

    setPagination({ commit, dispatch, state }, payload) {
      let samePagination = isSamePagination(state.paginationCollaborators, payload);

      commit('SET_PAGINATION', payload);

      if (!samePagination) {
        dispatch('fetchCollaborators');
      }
    },

    async fetchCollaborators({ commit, state }) {
      commit('SET_LOADING_COLLABORATORS', true);
      try {
        const filter = {
          ...state.filterCollaborators,
        };

        let paginationObject = state.paginationCollaborators;
        let pagination = definePaginationQuery(paginationObject);

        let response = await eSocialService.collaborators.filterByDesc(filter, pagination);

        let collabs = response.data.content;

        commit('SET_LIST_COLLABORATORS', collabs);
        commit('SET_TOTAL_COLLABORATORS', response.data.totalElements);
      } catch (e) {
        commit('SET_LIST_COLLABORATORS', []);
        console.error(e);
      } finally {
        commit('SET_LOADING_COLLABORATORS', false);
      }
    },

    async exportCollaborators({ commit }) {
      let response = await eSocialService.collaborators.export();
      commit('SET_RESULT_SCHEDULE_REPORT', response.status === 202);
    },
  },

  mutations: {
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReport = payload;
    },

    SET_FILTER_COLLABORATORS(state, payload) {
      state.filterCollaborators = payload;
    },

    SET_PAGINATION(state, payload) {
      state.paginationCollaborators = payload;
    },

    SET_LIST_COLLABORATORS(state, payload) {
      state.collaborators = payload;
    },

    SET_TOTAL_COLLABORATORS(state, payload) {
      state.totalCollaborators = payload;
    },

    SET_LOADING_COLLABORATORS(state, payload) {
      state.loadingCollaborators = payload;
    },
  },
};
