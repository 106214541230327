import eSocialService from '@/services/eSocialService.js';
import { definePaginationQuery, isSamePagination } from '@/utils/pagination';
import { cloneDeep } from 'lodash';

function cboOVerviewMapFiltersDataForRequest(filters, searchTerm) {
  let requestData = {};

  if (filters) {
    requestData.cnpjList = filters.cnpjList.map((item) => item.value);
  }

  if (searchTerm) {
    requestData.filterTerm = searchTerm;
  }

  return requestData;
}

function setDefaultState() {
  return {
    cboOverviewLoadingFilters: true,
    cboOverviewTableLoading: true,
    searchTerm: '',
    cboOverviewFiltersSaved: {
      cnpjList: [],
    },
    cboOverviewPagination: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },
    cboOverviewList: [],
    cboOverviewTotal: 0,
    cboOverviewScheduleReport: false,
    cboSearchTerm: '',
    cboSearchTableLoading: false,
    cboSearchTableItems: [],
    cboSearchTableItemsTotal: 0,
    cboSearchLoadingExport: false,
    cboSearchPagination: {
      itemsPerPage: 500,
      page: 1,
      sortBy: [],
    },
    cboSearchScheduleReport: false,
  };
}

export default {
  namespaced: true,

  state: setDefaultState(),

  getters: {
    getCboOverviewFiltersSaved(state) {
      return state.cboOverviewFiltersSaved;
    },
    getCboOverviewLoadingFilters(state) {
      return state.cboOverviewTableLoading;
    },
    getCboOverviewTableLoading(state) {
      return state.cboOverviewTableLoading;
    },
    getCboOverviewPagination(state) {
      return state.cboOverviewPagination;
    },
    getCboOverviewList(state) {
      return state.cboOverviewList;
    },
    getCboOverviewTotal(state) {
      return state.cboOverviewTotal;
    },
    getCboOverviewScheduleReport(state) {
      return state.cboOverviewScheduleReport;
    },
    getCboSearchTableLoading(state) {
      return state.cboSearchTableLoading;
    },
    getCboSearchTableItems(state) {
      return state.cboSearchTableItems;
    },
    getCboSearchTableItemsTotal(state) {
      return state.cboSearchTableItemsTotal;
    },
    getCboSearchTerm(state) {
      return state.cboSearchTerm;
    },
    getCboSearchLoadingExport(state) {
      return state.cboSearchLoadingExport;
    },
    getCboSearchScheduleReport(state) {
      return state.cboSearchScheduleReport;
    },
  },

  actions: {
    resetState({ commit }) {
      commit('SET_SEARCH_TERM', '');
      commit('SET_CBO_OVERVIEW_FILTERS_SAVED', {
        cnpjList: [],
      });
    },
    async fetchCboSuggestion({ commit, state }, payload) {
      commit('SET_CBO_SEARCH_TABLE_LOADING', true);
      commit('SET_CBO_SEARCH_TERM', payload);

      if (!payload) {
        commit('SET_CBO_SEARCH_TABLE_LOADING', false);
        return;
      }

      try {
        let pagination = definePaginationQuery(state.cboSearchPagination);
        let { data } = await eSocialService.cboSearch.getSearchResults(payload, pagination);

        data.content.forEach((item, index) => {
          item.id_column = index + 1;
        });
        commit('SET_CBO_SEARCH_TABLE_ITEMS_TOTAL', data.totalElements);
        commit('SET_CBO_SEARCH_TABLE_ITEMS', data.content);
      } catch (e) {
        commit('SET_CBO_SEARCH_TABLE_ITEMS_TOTAL', 0);
        commit('SET_CBO_SEARCH_TABLE_ITEMS', []);
      } finally {
        commit('SET_CBO_SEARCH_TABLE_LOADING', false);
      }
    },
    setCboSearchLoadingExportButton({ commit }, payload) {
      commit('SET_CBO_SEARCH_LOADING_EXPORT', payload);
    },
    async generatePDF({ commit }, payload) {
      try {
        let response = await eSocialService.cboSearch.exportCboSearch(payload);
        commit('SET_CBO_SEARCH_SCHEDULE_REPORT', response.status === 202);
      } catch (e) {
        console.error('Error exporting data: ', e);
        commit('SET_CBO_SEARCH_LOADING_EXPORT', false);
      }
      commit('SET_CBO_SEARCH_LOADING_EXPORT', false);
    },
    async fetchCboOverview({ commit, dispatch, state }) {
      dispatch('setCboOverviewLoadingFilters', true);
      commit('SET_CBO_OVERVIEW_TABLE_LOADING', true);
      let filters = cloneDeep(state.cboOverviewFiltersSaved);
      commit('SET_CBO_OVERVIEW_FILTERS_SAVED', filters);
      let paginationObject = state.cboOverviewPagination;
      let pagination = definePaginationQuery(paginationObject);
      let requestData = cboOVerviewMapFiltersDataForRequest(filters, state.searchTerm);
      try {
        const response = await eSocialService.cboOverview.getCboList(requestData, pagination);
        response.data.content.forEach((item, index) => {
          item.id_column = index + 1;
        });
        commit('SET_CBO_OVERVIEW_LIST', response.data.content);
        let totalElements = response.data.totalElements;
        commit('SET_CBO_OVERVIEW_TOTAL', totalElements);
        dispatch('setCboOverviewLoadingFilters', false);
        commit('SET_CBO_OVERVIEW_TABLE_LOADING', false);
      } catch (error) {
        console.error('AG_ERROR Error at fetchFilterSelects: ', error);
        dispatch('setCboOverviewLoadingFilters', false);
        commit('SET_CBO_OVERVIEW_TABLE_LOADING', false);
      }
    },
    async exportTableCboOverview({ commit, state }, searchTerm) {
      try {
        let filters = cloneDeep(state.cboOverviewFiltersSaved);
        filters.search = searchTerm;
        let requestData = cboOVerviewMapFiltersDataForRequest(filters);
        let response = await eSocialService.cboOverview.exportCboList(requestData);
        commit('SET_CBO_OVERVIEW_RESULT_SCHEDULE_REPORT', response.status === 202);
      } catch (e) {
        console.error('Error exporting CBO table: ', e);
      }
    },
    setCboOverviewPagination({ commit, state, dispatch }, payload) {
      let notSamePagination = !isSamePagination(payload.pagination, state.cboOverviewPagination);
      commit('SET_CBO_OVERVIEW_PAGINATION', payload.pagination);
      if (notSamePagination) {
        dispatch('fetchCboOverview');
      }
    },
    applyCboOverviewFilters({ commit, dispatch }, filters) {
      commit('SET_CBO_OVERVIEW_FILTERS_SAVED', filters);
      commit('RESET_CBO_OVERVIEW_PAGINATION');
      dispatch('fetchCboOverview');
    },
    applySearchTerm({ commit, dispatch }, searchTerm) {
      commit('SET_SEARCH_TERM', searchTerm);
      commit('RESET_CBO_OVERVIEW_PAGINATION');
      dispatch('fetchCboOverview');
    },
    clearCboOverviewFilters({ commit, dispatch }) {
      commit('SET_CBO_OVERVIEW_FILTERS_SAVED', { cnpjList: [] });
      commit('SET_SEARCH_TERM', '');
      dispatch('fetchCboOverview');
    },
    setCboOverviewLoadingFilters({ commit }, payload) {
      commit('SET_CBO_OVERVIEW_LOADING_FILTERS', payload);
    },
  },

  mutations: {
    SET_CBO_OVERVIEW_FILTERS_SAVED(state, payload) {
      state.cboOverviewFiltersSaved = cloneDeep(payload);
    },
    SET_SEARCH_TERM(state, payload) {
      state.searchTerm = cloneDeep(payload);
    },
    SET_CBO_OVERVIEW_PAGINATION(state, payload) {
      state.cboOverviewPagination = payload;
    },
    SET_CBO_OVERVIEW_LOADING_FILTERS(state, payload) {
      state.cboOverviewLoadingFilters = payload;
    },
    SET_CBO_OVERVIEW_TABLE_LOADING(state, payload) {
      state.cboOverviewTableLoading = payload;
    },
    SET_CBO_OVERVIEW_LIST(state, payload) {
      state.cboOverviewList = payload;
    },
    SET_CBO_OVERVIEW_TOTAL(state, payload) {
      state.cboOverviewTotal = payload;
    },
    SET_CBO_OVERVIEW_RESULT_SCHEDULE_REPORT(state, payload) {
      state.cboOverviewScheduleReport = payload;
    },
    SET_CBO_SEARCH_TABLE_LOADING(state, payload) {
      state.cboSearchTableLoading = payload;
    },
    SET_CBO_SEARCH_TABLE_ITEMS(state, payload) {
      state.cboSearchTableItems = payload;
    },
    SET_CBO_SEARCH_TABLE_ITEMS_TOTAL(state, payload) {
      state.cboSearchTableItemsTotal = payload;
    },
    SET_CBO_SEARCH_TERM(state, payload) {
      state.cboSearchTerm = payload;
    },
    SET_CBO_SEARCH_LOADING_EXPORT(state, payload) {
      state.cboSearchLoadingExport = payload;
    },
    SET_CBO_SEARCH_SCHEDULE_REPORT(state, payload) {
      state.cboSearchScheduleReport = payload;
    },
    RESET_CBO_OVERVIEW_PAGINATION(state) {
      state.cboOverviewPagination = {
        itemsPerPage: 50,
        page: 1,
        sortBy: [],
      };
    },
  },
};
