<template>
  <v-btn
    :id="id"
    :type="submit ? 'submit' : 'button'"
    :class="[{ fullWidth: fullWidth }, { 'v-btn--active': active }, typeClass]"
    :disabled="disabled"
    :loading="loading"
    :to="to"
    @click.native="onClick"
  >
    <AGIcon v-if="leftIcon" :icon="leftIcon" :size="iconSize" left />
    <slot />
    <AGIcon v-if="rightIcon" :icon="rightIcon" :size="iconSize" right />
  </v-btn>
</template>

<script>
import { uniqueId } from 'lodash';

export default {
  components: {
    AGIcon: () => import('@/router/views/commons/AGIcon.vue'),
  },

  props: {
    id: {
      type: String,
      default: uniqueId('ag_btn_'),
    },

    leftIcon: {
      type: String,
      default: '',
    },

    rightIcon: {
      type: String,
      default: '',
    },

    iconSize: {
      type: String,
      default: '16px',
    },

    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary', 'tertiary'].includes(value);
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    to: {
      type: [String, Object],
      default: undefined,
    },

    submit: {
      type: Boolean,
      default: false,
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    active: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    typeClass() {
      switch (this.type) {
        case 'primary':
          return 'btn-ag-primary';
        case 'secondary':
          return 'btn-ag-secondary';
        case 'tertiary':
        default:
          return 'btn-ag-tertiary';
      }
    },
  },

  methods: {
    onClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped lang="scss">
.fullWidth {
  width: 100% !important;
}
</style>
