import 'moment/locale/pt-br';
import moment from 'moment';

moment.locale('pt-br');

export default class UtilsDate {
  static today(format = 'YYYY-MM-DD') {
    return moment().format(format);
  }

  static format(value, format = 'DD/MM/Y') {
    if (!value) return;

    return moment(value).format(format);
  }

  static formatUS(value, format = 'YYYY-MM-DD') {
    if (!value) return;

    const parsedDate = moment(value, 'DD/MM/YYYY', true);

    if (!parsedDate.isValid()) {
      return 'Data inválida';
    }

    return parsedDate.format(format);
  }

  static isValid(value, format, strict = false) {
    return moment(value, format, strict).isValid();
  }

  static isAfter(firstValue, secondValue, format = 'YYYY-MM-DD') {
    return moment(firstValue, format).isAfter(secondValue);
  }
}
