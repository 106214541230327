import router from '@/router/index.js';
import { axiosWarSmartSolution } from '@/services/config.js';
let configforXLS = {
  responseType: 'arraybuffer',

  contentType: 'application/force-download',

  'Content-Disposition': 'attachment',
};
axiosWarSmartSolution.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push({ name: 'RouteLogin' }).then(() => {});
    }
    return Promise.reject(error);
  },
);

export default {
  wss: {
    categories: (params) => {
      return axiosWarSmartSolution.post('count-categories', params);
    },
    chartsCounts: (param) => {
      return axiosWarSmartSolution.post('charts-counts', param);
    },
    collaboratorRetirementRisks: (serialized, pagination) => {
      return axiosWarSmartSolution.post('retirement-risks?' + pagination, serialized.params);
    },
    riskAlerts: (serialized, pagination) => {
      return axiosWarSmartSolution.post('risk-alerts?' + pagination, serialized.params);
    },
    collaboratorRetirementRisksExport: (serialized) => {
      return axiosWarSmartSolution.post(`retirement-risks/export`, serialized.params);
    },
    countCollaboratorsByAgent: (serialized) => {
      return axiosWarSmartSolution.post('count-collaborators-by-agent?', serialized.params);
    },
    nociveAgents: (serialized, pagination) => {
      return axiosWarSmartSolution.post('nocive-agents?' + pagination, serialized.params);
    },
    nociveAgentsExport: (serialized) => {
      return axiosWarSmartSolution.post(`nocive-agents/export`, serialized.params);
    },
    riskAlertExport: (serialized) => {
      return axiosWarSmartSolution.post(`risk-alerts/export`, serialized.params);
    },
    historyImport: (serialized) => {
      return axiosWarSmartSolution.post('/import-history', serialized.params);
    },
    historyImportExport: (serialized) => {
      return axiosWarSmartSolution.post('/import-history/export', serialized.params, configforXLS);
    },
    availableCompetencies: () => {
      return axiosWarSmartSolution.post('/available-competencies');
    },
  },
};
