import router from '@/router/index.js';
import { axiosEsocial } from '@/services/config.js';

let config = { headers: { 'Content-Type': 'multipart/form-data' } };
let configforXML = {
  responseType: 'arraybuffer',
  contentType: 'application/force-download',
  'Content-Disposition': 'attachment',
};

// let configForZip = {
//   responseType: 'arraybuffer',
//   contentType: 'application/force-download',
//   'Content-Disposition': 'attachment',
// };

axiosEsocial.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push({ name: 'RouteLogin' });
    }
    return Promise.reject(error);
  },
);

export default {
  eSocialUpload: {
    getAll: () => {
      return axiosEsocial.get('file-process');
    },
    upload: (formData) => {
      return axiosEsocial.post('/file-process/upload/', formData, config);
    },
    getProcessHistory: (fileProcessId) => {
      return axiosEsocial.get('/file-process/history/' + fileProcessId);
    },
  },
  evtadmissao: {
    search: (params) => {
      return axiosEsocial.get('evtadmissao/search?' + params);
    },
  },
  evtdeslig: {
    search: (params) => {
      return axiosEsocial.get('evtdeslig/search', { params });
    },
  },
  evttabrubrica: {
    get: (id) => {
      return axiosEsocial.get('evttabrubrica/' + id);
    },
    search: (pagination, params) => {
      return axiosEsocial.post(`evttabrubrica/filter?${pagination}`, params);
    },
    searchHistoryEsocial: (params) => {
      return axiosEsocial.get('evttabrubrica/search-history-esocial', { params });
    },
    getCount: (params) => {
      return axiosEsocial.get('evttabrubrica/count?status=' + params);
    },
    save: (params) => {
      return axiosEsocial.post('evttabrubrica/save-new-rubrica', params);
    },
    getProcessCP: () => {
      return axiosEsocial.get('evttabrubrica-processo-cp');
    },
    getProcessIRRF: () => {
      return axiosEsocial.get('evttabrubrica-processo-irrf');
    },
    getProcessFGTS: () => {
      return axiosEsocial.get('evttabrubrica-processo-fgts');
    },
  },
  rubricahistorico: {
    search: (id) => {
      return axiosEsocial.get('rubricahistorico/evtrubrica/' + id);
    },
    searchRubricaHistory: (params) => {
      return axiosEsocial.get('rubricahistorico/evtrubrica?' + params);
    },
    save: (itemToSave) => {
      return axiosEsocial.post('rubricahistorico/save', itemToSave);
    },
    update: (itemToSave) => {
      return axiosEsocial.post('rubricahistorico/save' + itemToSave.id, itemToSave);
    },
    delete: (id) => {
      return axiosEsocial.delete('rubricahistorico/' + id);
    },
    download: (filter) => {
      return axiosEsocial.post('rubricahistorico/download-xls', filter, configforXML);
    },
  },
  establishment: {
    getByUserId: () => {
      return axiosEsocial.get('establishment-company/userid');
    },
  },
  report: {
    generate: (nrinscrideempregador) => {
      return axiosEsocial.get('/evtremuns/generate-report/' + nrinscrideempregador);
    },
    getAll: () => {
      return axiosEsocial.get('/report-process');
    },
  },
  collaborators: {
    getAll: () => {
      return axiosEsocial.post('/collaborator');
    },
    filterByDesc: (value, pagination) => {
      return axiosEsocial.post('/collaborator/?' + pagination, value);
    },
    export: () => {
      return axiosEsocial.post('/collaborator/export');
    },
  },
  rubricAnalysis: {
    analysis: (params, pagination) => {
      return axiosEsocial.post('/rubric-automation/analysis?' + pagination, params);
    },

    graphicGeneralVision: (params) => {
      return axiosEsocial.post('/rubric-automation/analysis/count/general-vision', params);
    },
    analysisCountPendingExplanation: (params) => {
      return axiosEsocial.post('/rubric-automation/analysis/count/pending-explanation', params);
    },
    export: (params) => {
      return axiosEsocial.post('/rubric-automation/analysis/export', params);
    },
  },
  rubricAutomationConfig: {
    rubricNatureList: (params, pagination) => {
      return axiosEsocial.post('/rubric-automation/config/nature?' + pagination, params);
    },
    rubricNatureSave: (rubricNature) => {
      return axiosEsocial.put('/rubric-automation/config/nature', rubricNature);
    },
    rubricNatureExport: (params) => {
      return axiosEsocial.post('/rubric-automation/config/nature/export', params);
    },

    natureTypesList: (params) => {
      return axiosEsocial.post('/rubric-automation/config/type', params);
    },
    natureTypesSave: (natureType) => {
      return axiosEsocial.put('/rubric-automation/config/type', natureType);
    },
    natureTypesExport: (natureType) => {
      return axiosEsocial.post('/rubric-automation/config/type/export', natureType);
    },
    incidenceINSSList: (params, pagination) => {
      return axiosEsocial.post('/rubric-automation/config/inss-incidence?' + pagination, params);
    },
    incidenceINSSSave: (incidenceINSS) => {
      return axiosEsocial.put('/rubric-automation/config/inss-incidence', incidenceINSS);
    },
    incidenceINSSExport: (params) => {
      return axiosEsocial.post('/rubric-automation/config/inss-incidence/export', params);
    },
    incidenceIRRFList: (params, pagination) => {
      return axiosEsocial.post('/rubric-automation/config/irrf-incidence?' + pagination, params);
    },
    incidenceIRRFSave: (incidenceIRRF) => {
      return axiosEsocial.put('/rubric-automation/config/irrf-incidence', incidenceIRRF);
    },
    incidenceIRRFExport: (params) => {
      return axiosEsocial.post('/rubric-automation/config/irrf-incidence/export', params);
    },
    incidenceFGTSList: (params) => {
      return axiosEsocial.post('/rubric-automation/config/fgts-incidence', params);
    },
    incidenceFGTSSave: (incidenceFGTS) => {
      return axiosEsocial.put('/rubric-automation/config/fgts-incidence', incidenceFGTS);
    },
    incidenceFGTSExport: (params) => {
      return axiosEsocial.post('/rubric-automation/config/fgts-incidence/export', params);
    },
    incidenceCPRPList: (params) => {
      return axiosEsocial.post('/rubric-automation/config/cprp-incidence', params);
    },
    incidenceCPRPSave: (incidenceCPRP) => {
      return axiosEsocial.put('/rubric-automation/config/cprp-incidence', incidenceCPRP);
    },
    incidenceCPRPExport: (params) => {
      return axiosEsocial.post('/rubric-automation/config/cprp-incidence/export', params);
    },
    history: (tableName, params) => {
      return axiosEsocial.post(`/rubric-automation/config/history/${tableName}`, params);
    },
    historyLatest: () => {
      return axiosEsocial.get(`/rubric-automation/config/history/latest`);
    },
    incidenceNatureList: (params, pagination) => {
      return axiosEsocial.post('/rubric-automation/config/incidence-by-nature?' + pagination, params);
    },
    incidenceNatureSave: (incidenceNature) => {
      return axiosEsocial.put('/rubric-automation/config/incidence-by-nature', incidenceNature);
    },
    incidenceNatureExport: (params) => {
      return axiosEsocial.post('/rubric-automation/config/incidence-by-nature/export', params);
    },
  },
  afastadosEInativos: {
    getDashboard: (value) => {
      return axiosEsocial.post('/collaborator/dashboard/active-and-retired', value);
    },
    getFilters: () => {
      return axiosEsocial.post('/collaborator/dashboard/filters', []);
    },
    getTable: (value, pagination) => {
      return axiosEsocial.post('/collaborator/history/active-and-retired?' + pagination, value);
    },
    export: (params) => {
      return axiosEsocial.post('/collaborator/history/active-and-retired/export', params);
    },
  },
  cedidos: {
    getDashboard: (filter) => {
      return axiosEsocial.post('/collaborator/dashboard/cessions', filter);
    },
    getFilters: () => {
      return axiosEsocial.post('/collaborator/dashboard/filters', []);
    },
    getTable: (value, pagination) => {
      return axiosEsocial.post('/collaborator/history/cessions?' + pagination, value);
    },
    getCount: (filter) => {
      return axiosEsocial.post('/collaborator/dashboard/cessions/count', filter);
    },
    export: (filter) => {
      return axiosEsocial.post('/collaborator/history/cessions/export', filter);
    },
  },
  cboOverview: {
    getCboList: (value, pagination) => {
      return axiosEsocial.post('/collaborator/history/cbo-analysis?' + pagination, value);
    },
    exportCboList: (filter) => {
      return axiosEsocial.post('/collaborator/history/cbo-analysis/export', filter);
    },
  },
  cboSearch: {
    getSearchResults: (value, pagination) => {
      return axiosEsocial.post('/collaborator/cbo?' + pagination, value);
    },
    exportCboSearch: (data) => {
      return axiosEsocial.post('/collaborator/cbo/export', data);
    },
  },
  cboClassification: {
    getCboList: (filter, pagination) => {
      return axiosEsocial.post('/cbo/list?' + pagination, filter);
    },
    exportCboList: (filter) => {
      return axiosEsocial.post('/cbo/export', filter);
    },
    getCboFilters: () => {
      return axiosEsocial.post('/cbo/dashboard/filters');
    },
    searchCboByParams: (filter, pagination) => {
      return axiosEsocial.post('/cboInfoes/search/findByFamilyID?' + pagination, filter);
    },
  },
  horarioEJornadas: {
    getHorarioEJornadasList: (filter, pagination) => {
      return axiosEsocial.post('/trabalhador-jornada/?' + pagination, filter);
    },
    exportHorarioEJornadasList: (filter) => {
      return axiosEsocial.post('/trabalhador-jornada/export', filter);
    },
    // getHorarioEJornadasFilters: () => {
    //   return axiosEsocial.post('/trabalhador-jornada/dashboard/filters');
    // },
  },
};
