<template>
  <v-app id="main">
    <router-view />
    <DialogScreenSizeWarning />
  </v-app>
</template>

<script>
import Theme from '@/mixins/Theme';
import DialogScreenSizeWarning from '@/router/layouts/components/DialogScreenSizeWarning.vue';

export default {
  name: 'App',
  components: { DialogScreenSizeWarning },
  mixins: [Theme],
};
</script>

<style scoped lang="scss"></style>
