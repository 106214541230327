import esocialAfastadosEInativos from './esocialAfastadosEInativos';
import esocialCbo from './esocialCbo';
import esocialCedidos from './esocialCedidos';
import esocialEmployers from './esocialEmployers';
import esocialJornadas from './esocialJornadas';
import esocialMain from './esocialModule';
import esocialRubricas from './esocialRubricas';
export default {
  namespaced: true,

  modules: {
    esocialAfastadosEInativos,
    esocialCbo,
    esocialCedidos,
    esocialEmployers,
    esocialJornadas,
    esocialMain,
    esocialRubricas,
  },
};
