function definePaginationQuery(paginationObject) {
  if ([undefined, null].includes(paginationObject.page)) {
    try {
      throw new Error('paginationObject object model is not in the pagination standard');
    } catch (e) {
      console.error(e);
    }
  }

  let pagination = `page=${paginationObject.page - 1}&size=${paginationObject.itemsPerPage}`;

  if (paginationObject.sortBy.length > 0) {
    pagination = `${pagination}&sort=${paginationObject.sortBy[0]},${
      paginationObject.sortDesc[0] === true ? 'desc' : paginationObject.sortDesc[0] === false ? 'asc' : ''
    }`;
  }

  return pagination;
}

function isSamePagination(pagination1, pagination2) {
  return pagination1.itemsPerPage === pagination2.itemsPerPage &&
    pagination1.page === pagination2.page &&
    pagination1.sortBy === pagination2.sortBy
    ? true
    : false;
}

module.exports = {
  definePaginationQuery: definePaginationQuery,
  isSamePagination: isSamePagination,
};
