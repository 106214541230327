import router from '@/router/index.js';
import { AxiosGed } from '@/services/config.js';

AxiosGed.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push('/');
    }
    return Promise.reject(error);
  },
);

export default {
  profile: {
    get: (id) => {
      return AxiosGed.get(`file/${id}`);
    },
    post: (entity) => {
      return AxiosGed.post('file', entity);
    },
  },

  files: {
    get: (id) => {
      return AxiosGed.get(`file/${id}`);
    },
    post: (entity) => {
      return AxiosGed.post('file', entity);
    },
  },

  meta: {
    get: (id) => {
      return AxiosGed.get(`file/${id}/meta`);
    },
  },
};
