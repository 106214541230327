<template>
  <div style="display: inline-block">
    <AGTooltip :tooltip-text="tooltip" :disabled="!tooltip" :direction="tooltipDirection">
      <v-btn
        :id="id"
        v-bind="$attrs"
        class="ag-btn-icon"
        icon
        :class="{ 'v-btn--active': active }"
        :to="to"
        :type="submit ? 'submit' : 'button'"
        :loading="loading"
        :disabled="disabled"
        :data-size="size"
        @click.native="onClick"
      >
        <AGIcon :icon="icon" :size="iconSize" />
      </v-btn>
    </AGTooltip>
  </div>
</template>

<script>
import { uniqueId } from 'lodash';

export default {
  components: {
    AGTooltip: () => import('@/router/views/commons/AGTooltip.vue'),
    AGIcon: () => import('@/router/views/commons/AGIcon.vue'),
  },

  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: uniqueId('ag_btn_icon_'),
    },

    tooltip: {
      type: String,
      default: '',
    },

    tooltipDirection: {
      type: String,
      default: 'top',
      validator: (val) => ['top', 'bottom', 'left', 'right'].includes(val),
    },

    icon: {
      type: String,
      default: '',
    },

    iconSize: {
      type: String,
      required: false,
    },

    size: {
      type: String,
      default: 'xl',
      validator(value) {
        return ['xl', 'lg', 'md', 'sm'].includes(value);
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    to: {
      type: [String, Object],
      default: undefined,
    },

    submit: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    active: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/design/components/btn.scss';
</style>
