import notificationService from '@/services/notificationServices.js';

export default {
  namespaced: true,

  state: {
    validPassword: false,
  },

  getters: {
    getValidPassword(state) {
      return state.validPassword;
    },
  },

  actions: {
    async wasLast3Passwords1({ commit }, data) {
      try {
        let response = await notificationService.wasLast3Passwords(data);
        commit('SET_VALID_PASSWORD', Object.freeze(response.data));
        return response.data;
      } catch (e) {
        console.error(e);
      }
    },
  },

  mutations: {
    SET_VALID_PASSWORD(state, payload) {
      state.validPassword = payload;
    },
  },
};
