import router from '@/router/index.js';
import { axiosInteraction } from '@/services/config.js';

let config = { headers: { 'Content-Type': 'multipart/form-data' } };

axiosInteraction.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push('/');
    }
    return Promise.reject(error);
  },
);

export default {
  visitor: {
    getToken: (email) => {
      return axiosInteraction.get('visitor/token/' + email);
    },
  },
  ticket: {
    findByUserMail: (email) => {
      return axiosInteraction.get('ticket/find/' + email);
    },
  },
  document: {
    download: (params) => {
      return axiosInteraction.post('document/schedule-export/', params);
    },
    get: (params, pagination) => {
      return axiosInteraction.get(`document?` + params + pagination);
    },
    markAsViewed: (onlyDownloads) => {
      return axiosInteraction.patch(`document/mark-as-viewed/${onlyDownloads}`);
    },
  },
  files: {
    get: (pagination) => {
      return axiosInteraction.get(`document?` + pagination);
    },
    upload: (formData) => {
      return axiosInteraction.post(`document`, formData, config);
    },
  },
};
