import documentsService from '@/services/interactionService.js';
import { definePaginationQuery } from '@/utils/pagination';
import { cloneDeep } from 'lodash';

export default {
  namespaced: true,

  state: {
    downloads: [],
    files: [],
    fileModalBool: false,
    fileModalItem: {},
    paginationFiles: { itemsPerPage: 50, page: 1, sortBy: [] },
    paginationDownloads: { itemsPerPage: 50, page: 1, sortBy: [] },
    totalArquivos: 0,
    totalDownloads: 0,
    loadingFileTable: true,
    loadingDownloadTable: true,
  },
  getters: {
    getShowModal(state) {
      return state.fileModalBool;
    },
    getFileModalItem(state) {
      return state.fileModalItem;
    },
  },
  actions: {
    async getFiles({ commit, state }) {
      commit('SET_LOADINGFILESTABLE', true);
      try {
        let paginationObject = cloneDeep(state.paginationFiles);
        let pagination = definePaginationQuery(paginationObject);
        let response = await documentsService.files.get(pagination);

        commit('SET_TOTALFILES', response.data.totalElements);
        commit('SET_FILES', response.data.content);
      } catch (e) {
        console.error('Error fetching anex files: ', e);
      } finally {
        commit('SET_LOADINGFILESTABLE', false);
      }
    },
    async getDownloads({ commit, state }) {
      commit('SET_LOADINGDOWNLOADTABLE', true);
      try {
        let paginationObject = cloneDeep(state.paginationDownloads);
        let pagination = definePaginationQuery(paginationObject);
        let report = 'report=true&';
        let response = await documentsService.document.get(report, pagination);

        commit('SET_TOTALDOWNLOADS', response.data.totalElements);
        commit('SET_DOWNLOADS', response.data.content);
      } catch (e) {
        console.error('Error fetching export files: ', e);
      } finally {
        commit('SET_LOADINGDOWNLOADTABLE', false);
      }
    },

    closeFileModal({ commit }, payload) {
      commit('SET_FILEMODALITEM', {});
      commit('SET_FILEMODALBOOL', payload);
    },
    openFileModal({ commit }, payload) {
      commit('SET_FILEMODALITEM', payload);
      commit('SET_FILEMODALBOOL', true);
    },

    setPaginationFiles({ dispatch, commit }, payload) {
      commit('SET_PAGINATIONFILES', payload);

      dispatch('getFiles');
    },

    setPaginationDownloads({ dispatch, commit }, payload) {
      commit('SET_PAGINATIONDOWNLOADS', payload);

      dispatch('getDownloads');
    },
  },

  mutations: {
    SET_DOWNLOADS(state, payload) {
      state.downloads = payload;
    },
    SET_LOADINGFILESTABLE(state, payload) {
      state.loadingFileTable = payload;
    },
    SET_LOADINGDOWNLOADTABLE(state, payload) {
      state.loadingDownloadTable = payload;
    },
    SET_FILES(state, payload) {
      state.files = payload;
    },
    SET_TOTALFILES(state, payload) {
      state.totalArquivos = payload;
    },
    SET_TOTALDOWNLOADS(state, payload) {
      state.totalDownloads = payload;
    },
    SET_FILEMODALBOOL(state, payload) {
      state.fileModalBool = payload;
    },
    SET_FILEMODALITEM(state, payload) {
      state.fileModalItem = payload;
    },
    SET_PAGINATIONFILES(state, payload) {
      state.paginationFiles = payload;
    },
    SET_PAGINATIONDOWNLOADS(state, payload) {
      state.paginationDownloads = payload;
    },
  },
};
