import router from '@/router';
import { axiosAdmin } from '@/services/config.js';

let configforXLS = {
  responseType: 'arraybuffer',

  contentType: 'application/force-download',

  'Content-Disposition': 'attachment',
};

axiosAdmin.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push({ name: 'RouteLogin' });
    }
    return Promise.reject(error);
  },
);

export default {
  digitalCertificate: {
    get: () => {
      return axiosAdmin.get('/certificate');
    },
    getByRoot: (root) => {
      return axiosAdmin.get(`/company-config/${root}`);
    },
    associate: (data) => {
      return axiosAdmin.post('company-config', data);
    },
    historic: (id) => {
      return axiosAdmin.get(`/company-config/certificate-history/${id}`);
    },
  },
  company: {
    get: (params) => {
      return axiosAdmin.get('company/search?', { params });
    },
    getWithConfigs: (params) => {
      return axiosAdmin.get('company/configs?', { params });
    },
    saveConfigs: (companyId, payload) => {
      return axiosAdmin.post(`company/${companyId}/configs`, payload);
    },
    robotsHistory: (rootCnpj) => {
      return axiosAdmin.get(`company-config/robots/history/${rootCnpj}`);
    },
  },
  group: {
    get: (params) => {
      return axiosAdmin.get('group/search?', { params });
    },
    save: (grupo) => {
      return axiosAdmin.post('group', grupo);
    },
  },
  employer: {
    getAll: (pagination) => {
      return axiosAdmin.get('companyestab?' + pagination);
    },
  },
  establishment: {
    get: (params) => {
      return axiosAdmin.get('establishment/search?', { params });
    },
    sendMessageToQueue: (cnpj) => {
      return axiosAdmin.get('establishment/sendmessagetoqueue?&cnpjEmpresaMatriz=' + cnpj);
    },
  },
  userEstablishment: {
    get: (id, onlyMatriz = false) => {
      return axiosAdmin.get('user-establishment/findbyuserid/' + id + (onlyMatriz ? '?onlyMatriz=true' : ''));
    },
    save: (data) => {
      return axiosAdmin.post('user-establishment', data);
    },
    saveAll: (data, id) => {
      return axiosAdmin.post('user-establishment/saveAll/' + id, data);
    },
    delete: (id) => {
      return axiosAdmin.delete('user-establishment/' + id);
    },
  },
  wizard: {
    get: (data) => {
      return axiosAdmin.get('company-details/save-cascade/' + data.cnpj + '/' + data.id);
    },
  },
  usability: {
    downloadUsabilityReport: (data) => {
      return axiosAdmin.post('usability', data, configforXLS);
    },
    findParams: (fromTime, toTime) => {
      return axiosAdmin.get('usability/filters/' + fromTime + '/' + toTime);
    },
  },
};
