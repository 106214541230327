export default class ClassIndicadoresFilter {
  constructor() {
    this.establishments = [];
    this.categories = [];
    this.rubrics = [];
    this.periods = '';
  }

  get isEmpty() {
    return !this.establishments.length && !this.categories.length && !this.rubrics.length && !this.periods;
  }
}
