<template>
  <v-dialog
    v-model="show"
    content-class="ag-dialog"
    scrollable
    :width="width"
    :max-width="maxWidth"
    :persistent="persistent"
  >
    <v-card class="ag-dialog__card" :max-height="height">
      <v-card-title v-if="title" class="ag-dialog__card-title">
        <span class="ag-dialog__card-title-text">{{ title }}</span>
        <AGButtonIcon
          id="dialog_btn_close"
          icon="mdi-close"
          size="md"
          :disabled="disabledCloseButton"
          @click="close()"
        />
      </v-card-title>

      <slot name="header" class="ag-dialog__card-title" />

      <v-divider class="ma-0" />

      <v-card-text class="ag-dialog__card-body" :no-padding="noPadding">
        <slot />
      </v-card-text>

      <template v-if="hasActions">
        <v-divider v-if="!hideFooterSeparator" class="ma-0" />

        <v-card-actions class="ag-dialog__card-footer">
          <AGButton
            v-if="!hideSecondaryButton"
            id="btn_dialog_secondary"
            type="secondary"
            :left-icon="secondaryButtonIcon"
            :disabled="disabledSecondaryButton"
            :loading="loadingSecondaryButton"
            @click="$emit('secondary-click')"
          >
            {{ secondaryButtonText }}
          </AGButton>
          <v-spacer />
          <AGButton
            v-if="!hideTertiaryButton"
            id="btn_dialog_tertiary"
            type="tertiary"
            :left-icon="tertiaryButtonIcon"
            :disabled="disabledTertiaryButton"
            :loading="loadingTertiaryButton"
            @click="$emit('tertiary-click')"
          >
            {{ tertiaryButtonText }}
          </AGButton>
          <AGButton
            v-if="!hidePrimaryButton"
            id="btn_dialog_primary"
            type="primary"
            :left-icon="primaryButtonIcon"
            :disabled="disabledPrimaryButton"
            :loading="loadingPrimaryButton"
            @click="$emit('primary-click')"
          >
            {{ primaryButtonText }}
          </AGButton>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import AGButton from '@/router/views/commons/AGButton.vue';
import AGButtonIcon from '@/router/views/commons/AGButtonIcon.vue';

export default {
  components: {
    AGButtonIcon,
    AGButton,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },

    title: {
      type: String,
      default: '',
    },

    noPadding: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
      default: '500px',
    },

    maxWidth: {
      type: String,
      default: '500px',
    },

    height: {
      type: String,
      default: '550px',
    },

    primaryButtonText: {
      type: String,
      default: 'Salvar',
    },

    secondaryButtonText: {
      type: String,
      default: 'Cancelar',
    },

    tertiaryButtonText: {
      type: String,
      default: 'Cancelar',
    },

    hidePrimaryButton: {
      type: Boolean,
      default: false,
    },

    hideSecondaryButton: {
      type: Boolean,
      default: false,
    },

    hideTertiaryButton: {
      type: Boolean,
      default: false,
    },

    primaryButtonIcon: {
      type: String,
      default: '',
    },

    secondaryButtonIcon: {
      type: String,
      default: '',
    },

    tertiaryButtonIcon: {
      type: String,
      default: '',
    },

    persistent: {
      type: Boolean,
      default: false,
    },

    disabledPrimaryButton: {
      type: Boolean,
      default: false,
    },

    loadingPrimaryButton: {
      type: Boolean,
      default: false,
    },

    disabledSecondaryButton: {
      type: Boolean,
      default: false,
    },

    loadingSecondaryButton: {
      type: Boolean,
      default: false,
    },

    disabledTertiaryButton: {
      type: Boolean,
      default: false,
    },

    loadingTertiaryButton: {
      type: Boolean,
      default: false,
    },

    hideFooterSeparator: {
      type: Boolean,
      default: false,
    },

    disabledCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['input', 'close', 'primary-click', 'secondary-click', 'tertiary-click'],

  computed: {
    show: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      },
    },

    hasActions() {
      return !this.hidePrimaryButton || !this.hideSecondaryButton || !this.hideTertiaryButton;
    },
  },

  methods: {
    close() {
      this.show = false;
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .ag-dialog.v-dialog--active {
  .ag-dialog__card {
    padding: 0 !important;
    border: 0 !important;
    display: flex !important;
    flex-direction: column;

    .ag-dialog__card-title {
      padding: 16px;
      display: flex;
      gap: 12px;
      align-items: flex-start;
      justify-content: space-between;

      .ag-dialog__card-title-text {
        flex: 1;
        word-break: break-word;
      }
    }

    .ag-dialog__card-body {
      padding: 16px;
      font-size: 16px;
      color: $neutral-color-high-medium;

      &[no-padding='true'] {
        padding: 0 !important;
      }
    }

    .ag-dialog__card-footer {
      gap: 16px;
      padding: 16px;

      > .v-btn.v-btn + .v-btn {
        margin: 0 !important;
      }
    }
  }
}
</style>
