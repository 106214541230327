export default {
  namespaced: true,

  state: {
    route:{
        to: '',
        from: '',
    },
  },
  getters: {
    route(state) {
      return state.route;
    },
  },

  actions: {
     setRoute({ commit }, payload) {
      commit('SET_ROUTE',payload);
    //   commit('SET_FROM',payload.from);
    },

  },

  mutations: {
    SET_ROUTE(state, payload) {
      state.route = payload;
    },
  },
};
