import { axiosEcac } from '@/services/config.js';
import router from '@/router/index.js';
import qs from 'qs';

axiosEcac.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push({ name: 'RouteLogin' });
    }
    return Promise.reject(error);
  },
);

export default {
  findDCTF: (filters, pagination) => {
    return axiosEcac.post('/search?' + pagination, filters);
  },
  getDCTF: (id) => {
    return axiosEcac.get(`/${id}/dctf-detalhe`);
  },
  findDCOMP: (dctfId, id) => {
    return axiosEcac.get(`/${dctfId}/dctf-detalhe/${id}/dcomp-dctf-detalhe`);
  },
  ecacProcess: {
    getAll: () => {
      return axiosEcac.get('ecac-process');
    },
    startIndex: () => {
      return axiosEcac.get('ecac-process/start-index');
    },
  },
};
