const ThemeType = {
  SYSTEM: 'SYSTEM',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

export default {
  data() {
    return {
      themeApp: ThemeType.SYSTEM,
    };
  },

  computed: {
    isDarkTheme() {
      switch (this.themeApp) {
        case ThemeType.LIGHT:
          return false;
        default:
          return false;
      }
    },
  },

  created() {
    const theme = localStorage.getItem('theme');
    if (theme) this.themeApp = theme;
    this.handleThemeDark();
  },

  methods: {
    handleThemeDark() {
      this.$vuetify.theme.dark = this.isDarkTheme;
      localStorage.setItem('theme', this.themeApp);
    },
  },
};
