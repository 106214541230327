import eSocialService from '@/services/eSocialService.js';
import { definePaginationQuery } from '@/utils/pagination';
import { cloneDeep } from 'lodash';

function filtersDefaultState() {
  return { cnpj: [] };
}

function mapFiltersDataForRequest(filters, searchTerm) {
  let requestData = {};
  if (filters) {
    if (filters.cnpj?.length > 0) {
      requestData.cnpjs = filters.cnpj;
    }
  }
  if (filters.code) {
    requestData.code = filters.code;
  }
  if (searchTerm) {
    requestData.tableIdCodeNameDescNature = `${searchTerm}$nml$$lk$`;
  }

  return requestData;
}

function paginationDefaultState() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}

export default {
  namespaced: true,
  state: {
    dataGraphs: {},
    loadingGraphs: true,
    scheduleReportStatus: false,
    searchTerm: '',
    filtersSaved: filtersDefaultState(),
    loadingFilters: true,
    analysisList: [],
    totalAnalysis: 0,
    pagination: paginationDefaultState(),
  },
  getters: {
    getFiltersSaved(state) {
      return state.filtersSaved;
    },
    getDataGraphs(state) {
      return state.dataGraphs;
    },
    getLoadingGraphs(state) {
      return state.loadingGraphs;
    },
    getLoadingFilters(state) {
      return state.loadingFilters;
    },
    getAnalysisList(state) {
      return state.analysisList;
    },
    getTotalAnalysis(state) {
      return state.totalAnalysis;
    },
    getScheduleReportStatus(state) {
      return state.scheduleReportStatus;
    },
    getPagination(state) {
      return state.pagination;
    },
  },
  mutations: {
    SET_DATA_GRAPHS(state, payload) {
      state.dataGraphs = payload;
    },
    SET_LOADING_GRAPHS(state, payload) {
      state.loadingGraphs = payload;
    },
    SET_FILTERS_SAVED(state, payload) {
      state.filtersSaved = cloneDeep(payload);
    },
    SET_SEARCH_SAVED(state, payload) {
      state.searchTerm = cloneDeep(payload);
    },
    SET_LOADING_FILTERS(state, payload) {
      state.loadingFilters = payload;
    },
    SET_ANALYSIS_LIST(state, payload) {
      state.analysisList = payload;
    },
    SET_TOTAL_ANALYSIS(state, payload) {
      state.totalAnalysis = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReportStatus = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
  },
  actions: {
    init({ commit }) {
      commit('SET_DATA_GRAPHS', {});
      commit('SET_LOADING_GRAPHS', true);
      commit('SET_FILTERS_SAVED', filtersDefaultState());
      commit('SET_ANALYSIS_LIST', []);
      commit('SET_TOTAL_ANALYSIS', 0);
    },
    setLoadingGraphs({ commit }, payload) {
      commit('SET_LOADING_GRAPHS', payload);
    },
    setPagination({ dispatch, commit }, payload) {
      dispatch('setLoadingFilters', true);
      commit('SET_PAGINATION', payload);
      dispatch('fetchAnalysis');
    },
    async fetchDataGraphs({ commit, dispatch, state }) {
      dispatch('setLoadingGraphs', true);
      let filters = cloneDeep(state.filtersSaved);
      let requestData = mapFiltersDataForRequest(filters);

      try {
        const data = (await eSocialService.rubricAnalysis.graphicGeneralVision(requestData)).data;
        data.byAnalysis.total = data.byAnalysis.emAnalise + data.byAnalysis.disponivelParaUso;
        data.byAnalysis.emAnalisePercent = (data.byAnalysis.emAnalise * 100) / data.byAnalysis.total;
        data.byAnalysis.disponivelParaUsoPercent = (data.byAnalysis.disponivelParaUso * 100) / data.byAnalysis.total;

        commit('SET_DATA_GRAPHS', data);
        if (data.byAnalysis.total > 0) {
          dispatch('setLoadingGraphs', false);
        }
      } catch (e) {
        console.error('AG_ERROR Error at fetchDataGraphs: ', e);
        commit('SET_DATA_GRAPHS', {});
        dispatch('setLoadingGraphs', false);
      }
    },
    async fetchSearchAnalysis({ commit, dispatch }, filters) {
      dispatch('setLoadingFilters', true);
      commit('SET_FILTERS_SAVED', filters);
      dispatch('fetchAnalysis');
    },
    setSearchTerm({ commit, dispatch }, searchTerm) {
      commit('SET_SEARCH_SAVED', searchTerm);
      dispatch('setPagination', paginationDefaultState());
    },
    async fetchAnalysis({ commit, dispatch, state }) {
      dispatch('setLoadingFilters', true);
      let filters = cloneDeep(state.filtersSaved);
      let paginationObject = state.pagination;
      let pagination = definePaginationQuery(paginationObject);
      let requestData = mapFiltersDataForRequest(filters, state.searchTerm);
      try {
        const response = await eSocialService.rubricAnalysis.analysis(requestData, pagination);
        response.data.content.forEach((item, index) => {
          item.id_column = index + 1;
        });
        commit('SET_ANALYSIS_LIST', response.data.content);
        let totalElements = response.data.totalElements;
        commit('SET_TOTAL_ANALYSIS', totalElements);
        dispatch('fetchDataGraphs');
        dispatch('setLoadingFilters', false);
      } catch (error) {
        console.error('AG_ERROR Error at fetchFilterSelects: ', error);
      }
    },

    applyFilters({ commit, dispatch }, payload) {
      commit('SET_FILTERS_SAVED', payload);
      dispatch('fetchAnalysis');
    },
    clearFilters({ commit, dispatch }) {
      commit('SET_FILTERS_SAVED', filtersDefaultState());
      dispatch('fetchDataGraphs', filtersDefaultState());
      dispatch('fetchAnalysis', filtersDefaultState());
    },

    async exportAutoAnalysis({ commit, state }) {
      let filters = cloneDeep(state.filtersSaved);

      let requestData = mapFiltersDataForRequest(filters);
      let response = await eSocialService.rubricAnalysis.export(requestData);
      commit('SET_RESULT_SCHEDULE_REPORT', response.status === 202);
    },
    setLoadingFilters({ commit }, payload) {
      commit('SET_LOADING_FILTERS', payload);
    },
  },
};
