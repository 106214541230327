import router from '@/router/index.js';
import { axiosTotalizadores } from '@/services/config.js';

axiosTotalizadores.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {

    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push('/');
    }
    return Promise.reject(error);
  },
);

export default {
  financial: {
    getAll: () => {
      return axiosTotalizadores.get('creditos');
    },
    getById: (idTotalizador) => {
        return axiosTotalizadores.get('creditos/' + idTotalizador);
    },
    save: (params) => {
      return axiosTotalizadores.post('creditos', params);
    },
    update: (totalizador) => {

      return axiosTotalizadores.put('creditos/' + totalizador.id, totalizador);
    },
    delete: (idTotalizador) => {
      return axiosTotalizadores.delete('creditos/' + idTotalizador);
    },
    hasNewTotalizers: () => {
      return axiosTotalizadores.get('creditos/hasnewtotalizers');
    },
  },
};
