import Vue from 'vue';
import vueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {};

Vue.use(vueSweetalert2, options);

const toast = Vue.swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Vue.swal.stopTimer);
    toast.addEventListener('mouseleave', Vue.swal.resumeTimer);
  },
});

Vue.swal.toast = toast.fire.bind(toast);
