import TotalizadorService from '@/services/totalizadorService.js';
import cloneDeep from 'lodash/cloneDeep';

export default {
  namespaced: true,

  state: {
    totalizadores: [],
    totalTotalizadores: 0,
    loadingTotalizadores: true,
  },

  getters: {
    getTotalizadores(state) {
      return state.totalizadores;
    },
    getLoadingTotalizadores(state) {
      return state.loadingTotalizadores;
    },
    getTotalTotalizadores(state) {
      return state.totalTotalizadores;
    },
  },

  actions: {
    async fetchTotalizadores({ commit }) {
      commit('SET_LOADING_TOTALIZADORES', true);
      try {
        let response = await TotalizadorService.financial.getAll();
        commit('SET_TOTALIZADORES', response.data);
        if (![undefined, null, ''].includes(response.data)) {
          commit('SET_TOTAL_TOTALIZADORES', response.data.length);
        }
        commit('SET_LOADING_TOTALIZADORES', false);
      } catch (e) {
        console.error('Error fetching totalizadores api: ', e);
        commit('SET_LOADING_TOTALIZADORES', false);
      }
    },

    saveTotalizador(_, payload) {
      try {
        TotalizadorService.financial.save(payload);
      } catch (e) {
        if (![undefined, null].includes(e.response)) {
          console.error(e.response);
        } else {
          console.error(e);
        }
      }
    },
    editTotalizador(_, payload) {
      try {
        TotalizadorService.financial.update(payload);
      } catch (e) {
        if (![undefined, null].includes(e.response)) {
          console.error(e.response);
        } else {
          console.error(e);
        }
      }
    },
  },

  mutations: {
    SET_TOTALIZADORES(state, payload) {
      state.totalizadores = cloneDeep(payload);
    },

    SET_LOADING_TOTALIZADORES(state, payload) {
      state.loadingTotalizadores = payload;
    },

    SET_TOTAL_TOTALIZADORES(state, payload) {
      state.totalTotalizadores = payload;
    },
  },
};
