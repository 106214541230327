import rubrics from '@/assets/json/rubrics';
import ClassIndicadoresFilter from '@/router/views/indicadores/class/ClassIndicadoresFilter';
import indexService from '@/services/indexService.js';
import { cloneDeep, isEmpty } from 'lodash';

function mapFiltersDataForRequest(filters) {
  let requestData = {};

  if (filters) {
    requestData.natRubrDadosrubrica = filters.rubrics;
    requestData.cnpjs = filters.establishments;
    requestData.perApur = filters.periods;
    requestData.categoryCodes = filters.categoryCode;
  }

  return requestData;
}
export default {
  namespaced: true,

  state: {
    loading: true,
    companies: [],
    establishments: [],
    categoryCode: [],
    rubrics: rubrics,
    periods: [],
    establishmentsGroupedBySocialName: [],
    listGroupItems: [],

    dataIndex: null,
    filter: {
      establishments: [],
      categories: [],
      rubrics: [],
      periods: [],
    },
  },
  getters: {
    getCategoriesList(state) {
      return state.categoryCode.map((item) => {
        return item.subtitle
          ? {
              id: item.subtitle,
              descricao: item.subtitle,
              isGroup: true,
            }
          : { ...item, codigo: item.codigo };
      });
    },
  },
  mutations: {
    SET_CATEGORIES(state, payload) {
      state.categoryCode = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    SET_PERIODS(state, payload) {
      state.periods = payload;
    },
    SET_DATA_INDEX(state, payload) {
      state.dataIndex = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },

  actions: {
    async init({ commit, state, rootState }) {
      commit('SET_PERIODS', mountPeriods());
      let initialFilter = await mountInitialFilter(state.rubrics, state.periods, rootState.user.userEstablishments);
      commit('SET_FILTER', initialFilter);

      return state.filter;
    },

    applyFilter({ commit, dispatch }, filter) {
      commit('SET_FILTER', cloneDeep(filter));

      dispatch('fetchGraphs');
    },

    async fetchCategories({ commit }) {
      try {
        const response = await indexService.indexProcess.getCategories();
        let dataReturned = response.data;

        commit('SET_CATEGORIES', dataReturned);
      } catch (e) {
        console.error('Error fetching categories: ', e);
      }
    },

    async fetchGraphs({ commit, state }) {
      commit('SET_LOADING', true);

      try {
        commit('SET_DATA_INDEX', null);
        let filter = state.filter;

        if (filter.rubrics.length && filter.establishments.length && filter.periods.length) {
          let requestData = mapFiltersDataForRequest(filter);
          const { data } = await indexService.evtremuns.get(requestData);

          data.graphOne = data.graphOne.map((item) => {
            let code = item.name.substring(0, 4);

            item.name = item.name.replace('-', `- ${state.rubrics.find((x) => x.codigo == code).nome}`);

            return item;
          });
          if (!isEmpty(data)) {
            commit('SET_DATA_INDEX', data);
          }
        }
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },
};

function mountPeriods() {
  let periods = [];
  const currentYear = new Date().getFullYear();

  for (let year = 2010; year <= currentYear; year++) {
    periods.push(year);
  }

  return periods.reverse();
}

function mountInitialFilter(rubrics, periods, establishmentsList) {
  const firstValidCnpj = establishmentsList.find((item) => item.details.cnpj != null)?.details.cnpj;

  const filter = new ClassIndicadoresFilter();
  filter.establishments = [firstValidCnpj.toString()];
  filter.rubrics = [rubrics[0].codigo.toString()];
  filter.periods = periods[0].toString();

  return filter;
}
