import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { pt } from '../i18n/pt-br';

Vue.use(VueI18n);

const messages = {
  pt,
};

const i18n = new VueI18n({
  locale: 'pt',
  messages,
});

export default i18n;
