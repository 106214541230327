<template>
  <AGDialog
    v-model="showWarningScreenSize"
    title="Importante!"
    primary-button-text="Entendi"
    width="550px"
    height="600px"
    hide-secondary-button
    hide-tertiary-button
    no-padding
    @primary-click="showWarningScreenSize = false"
  >
    <v-container class="screen-warning__container">
      <img class="logo" src="./../../../assets/logo-cor-primaria.png" alt="Agnes" style="height: 32px" />

      <div class="screen-warning__title">Observamos que você está<br />usando uma tela menor.</div>

      <div class="screen-warning__text">
        Para uma experiência ideal, recomendamos utilizar este sistema em uma resolução mínima de 1366 pixels de
        largura. Algumas funcionalidades podem não estar totalmente otimizadas para resoluções menores.
        <br />
        <br />
        <b>
          Se precisar de assistência ou tiver alguma dúvida,<br />
          estamos aqui para ajudar!
        </b>
        <br />
        Entre em contato pelo chat Agnes ou pelo e-mail suporte@agnes.com.br
      </div>
    </v-container>
  </AGDialog>
</template>

<script>
import AGDialog from '@/router/views/commons/AGDialog.vue';

export default {
  components: { AGDialog },
  data() {
    return {
      showWarningScreenSize: true,
    };
  },

  created() {
    this.showWarningScreenSize = window.innerWidth < 1366;
  },
};
</script>

<style scoped lang="scss">
.screen-warning__container {
  background: $neutral-color-low-medium;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.screen-warning__title {
  color: $brand-color-primary-medium;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  letter-spacing: 0.24px;
}

.screen-warning__text {
  color: $neutral-color-high-medium;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
}
</style>
