import eSocialService from '@/services/eSocialService.js';
import { cnpj } from '@/utils/mask';
import { definePaginationQuery, isSamePagination } from '@/utils/pagination';
import { cloneDeep } from 'lodash';

function defaultPagination() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}

function filtersDefaultState() {
  return {
    filterTerm: null,
    originCnpjList: [],
    destinationCnpjList: [],
    tpRegPrevList: [],
    periodo: [],
    originRemunDeclarationToggle: null,
    isCeded: false,
    isNotCeded: false,
    isCededOnCurrentDate: false,
    filterTerm: null,
  };
}

function mapUniquesValuesFromArray(itemList) {
  return Array.from(new Map(itemList.map((item) => [item.itemId, item])).values());
}

function mapFiltersDataForRequest(filters) {
  let requestData = {};

  if (filters) {
    requestData.originRemunDeclarationToggle =
      filters.originRemunDeclarationToggle === 'with_declaration'
        ? true
        : filters.originRemunDeclarationToggle === true
          ? true
          : filters.originRemunDeclarationToggle === false
            ? false
            : filters.originRemunDeclarationToggle === 'no_declaration'
              ? false
              : null;
    requestData.startDate = filters.periodo[0];
    requestData.endDate = filters.periodo[1];
    requestData.originCnpjList = filters.originCnpjList.map((item) => item.itemId);
    requestData.destinationCnpjList = filters.destinationCnpjList.map((item) => item.itemId);
    requestData.tpRegPrevList = filters.tpRegPrevList.map((item) => item.itemId);
    requestData.isCeded = !filters.isCeded && !filters.isNotCeded ? null : !filters.isNotCeded;
    requestData.isCededOnCurrentDate = filters.isCededOnCurrentDate;

    if (filters.filterTerm) {
      requestData.filterTerm = filters.filterTerm;
    }
  }

  return requestData;
}

function getPercentual(x, total) {
  let percentual = 0;
  if (total) {
    percentual = ((x * 100) / total).toFixed(2);
  }
  return Number(percentual);
}

function isEmpty(payload) {
  return Number(payload ?? 0);
}

function defaultCardGraphs() {
  return [
    {
      title: 'Total de cessão/Exercício em Outro Órgão',
      value: [0, 100],
      valueTot: 0,
      percentual: '0%',
      description: ' em relação ao total de servidores',
      color: ['var(--v-primaryMedium-base)', 'var(--v-lowDark-base)'],
      name: 'totCessao',
      status: true,
    },
    {
      title: 'Cedidos com declaração de remuneração no órgão de origem',
      value: [0, 100],
      valueTot: 0,
      percentual: '0%',
      description: ' em relação ao total de cedidos',
      color: ['var(--v-primaryMedium-base)', 'var(--v-lowDark-base)'],
      name: 'cedidoComDeclaracao',
      status: false,
    },
    {
      title: 'Cedidos sem declaração de remuneração no órgão de origem',
      value: [0, 100],
      valueTot: 0,
      percentual: '0%',
      description: ' em relação ao total de cedidos',
      color: ['var(--v-primaryMedium-base)', 'var(--v-lowDark-base)'],
      name: 'cedidoSemDeclaracao',
      status: false,
    },
  ];
}

export default {
  namespaced: true,
  state: {
    loadingGraphs: true,
    loadingFilters: true,
    colaboradoresList: [],
    scheduleReportStatus: false,
    totalColaboradores: 0,
    filtersSaved: filtersDefaultState(),
    count: 0,
    countIsNotCeded: 0,
    countCeded: 0,
    isCardOneDisabled: false,
    loadingColaboradoresList: true,
    isCardTwoDisabled: false,
    isCardThreeDisabled: false,
    graphData: {},
    cardGraphData: defaultCardGraphs(),
    selectRegPrev: [],
    selectDestinationCnpjList: [],
    selectOriginCnpjList: [],
    pagination: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },
  },
  getters: {
    getCountIsNotCeded(state) {
      return state.countIsNotCeded;
    },
    getCountCeded(state) {
      return state.countCeded;
    },
    getCount(state) {
      return state.count ?? 0;
    },
    getTotCollaborators(state) {
      return state.totalColaboradores;
    },
    getLoadingColaboradoresList(state) {
      return state.loadingColaboradoresList;
    },
    getPagination(state) {
      return state.pagination;
    },
    getSelectDestinationCnpjList(state) {
      return state.selectDestinationCnpjList;
    },
    getSelectOriginCnpjList(state) {
      return state.selectOriginCnpjList;
    },
    getSelectRegPrev(state) {
      return state.selectRegPrev;
    },
    getFiltersSaved(state) {
      return state.filtersSaved;
    },
    getColaboradoresList(state) {
      return state.colaboradoresList;
    },
    getLoadingGraphs(state) {
      return state.loadingGraphs;
    },
    getLoadingFilters(state) {
      return state.loadingFilters;
    },
    getDataForGraph(state) {
      return state.graphData;
    },
    getCardGraphData(state) {
      return state.cardGraphData;
    },
    getScheduleReportStatus(state) {
      return state.scheduleReportStatus;
    },
  },
  actions: {
    init({ dispatch, commit }) {
      commit('SET_BARGRAPH_DATA', {});
      commit('SET_CARDGRAPH_DATA', defaultCardGraphs());
      commit('SET_COLABORADORES_LIST', []);
      commit('SET_FILTERS_SAVED', filtersDefaultState());
      dispatch('fetchTableData');
      dispatch('getFilters');
    },
    setPagination({ dispatch, commit, state }, payload) {
      commit('SET_LOADING_COLABORADORES_LIST', true);

      let samePagination = isSamePagination(state.pagination, payload);

      commit('SET_PAGINATION', payload);

      if (!samePagination) {
        dispatch('fetchTableData');
      }
    },
    async getFilters({ commit, dispatch }) {
      let responseFilter = (await eSocialService.cedidos.getFilters()).data;
      let uniqueOriginCnpjsList = mapUniquesValuesFromArray(responseFilter.groupOriginCnpjList);
      let uniqueDestinationCnpjsList = mapUniquesValuesFromArray(responseFilter.groupDestinationCnpjList);

      commit('SET_SELECT_REGPREV', responseFilter.groupRegimesPrevidenciarios);
      commit('SET_SELECT_ORIGINCNPJLIST', uniqueOriginCnpjsList);
      commit('SET_SELECT_DESTINATIONCNPJLIST', uniqueDestinationCnpjsList);

      dispatch('getDashboard');
    },
    async getDashboard({ commit, dispatch, state }, payload) {
      commit('SET_GRAPHLOADING', true);
      commit('SET_LOADING_COLABORADORES_LIST', true);
      commit('SET_FILTER_LOADING', true);

      commit('SET_BARGRAPH_DATA', { data: [], series: [] });

      commit('SET_CARDGRAPH_DATA', defaultCardGraphs());
      commit('SET_FILTERS_SAVED', payload ?? filtersDefaultState());

      let filters = cloneDeep(state.filtersSaved);

      try {
        let requestData = mapFiltersDataForRequest(filters);
        const resCounts = await eSocialService.cedidos.getCount(requestData);

        if (resCounts.data) {
          if (!payload) {
            commit('SET_COUNT', resCounts.data.cededOnCurrentDate);
          }
          commit('SET_COUNT_IS_NOT_CEDED', resCounts.data.notCeded);
          commit('SET_COUNT_CEDED', resCounts.data.ceded);
        }

        let resDashboard = await eSocialService.cedidos.getDashboard(requestData);

        if (resDashboard.data) {
          if (resDashboard.data.cessionRemunerationByPeriod) {
            let cessionRemunerationByPeriod = resDashboard.data.cessionRemunerationByPeriod[0];
            commit('SET_BARGRAPH_DATA', cessionRemunerationByPeriod);
          }

          if (payload) {
            dispatch('fetchTableData');
          }
        }
      } finally {
        commit('SET_GRAPHLOADING', false);
        commit('SET_FILTER_LOADING', false);
        commit('SET_LOADING_COLABORADORES_LIST', false);
      }
    },
    async cardGraphs({ commit, state }, payload) {
      let withDeclaration = isEmpty(payload.remuneracaoCedenteCount);
      let withoutDeclaration = isEmpty(payload.remuneracaoCessionarioCount);
      let total = isEmpty(withDeclaration) + isEmpty(withoutDeclaration);

      let percentualTotal = getPercentual(total, total);
      let percentualWithDeclaration = getPercentual(withDeclaration, total);
      let percentualWithoutDeclaration = getPercentual(withoutDeclaration, total);

      let types = [
        {
          title: 'Total de cessão/Exercício em Outro Órgão',
          value: percentualTotal === 0 ? [0, 1] : [percentualTotal, 0],
          valueTot: total,
          percentual: percentualTotal + '%',
          description: ' em relação ao total de servidores',
          color: ['var(--v-primaryMedium-base)', 'var(--v-lowDark-base)'],
          name: 'totCessao',
          status: cloneDeep(state.isCardOneDisabled),
        },
        {
          title: 'Cedidos <strong>com declaração</strong> de remuneração no órgão de origem',
          value:
            percentualWithDeclaration === 0 && percentualWithoutDeclaration === 0
              ? [0, 1]
              : [percentualWithDeclaration, percentualWithoutDeclaration],
          valueTot: withDeclaration,
          percentual: percentualWithDeclaration + '%',
          description: ' em relação ao total de cedidos',
          color: [
            'var(--v-primaryMedium-base)',
            withDeclaration === 0 ? 'var(--v-lowMedium-base)' : 'var(--v-lowDark-base)',
          ],
          name: 'cedidoComDeclaracao',
          status: cloneDeep(state.isCardTwoDisabled),
        },
        {
          title: 'Cedidos <strong>sem declaração</strong> de remuneração no órgão de origem',
          value:
            percentualWithDeclaration === 0 && percentualWithoutDeclaration === 0
              ? [0, 1]
              : [percentualWithoutDeclaration, percentualWithDeclaration],
          valueTot: withoutDeclaration,
          percentual: percentualWithoutDeclaration + '%',
          description: ' em relação ao total de cedidos',
          color: [
            'var(--v-primaryMedium-base)',
            withoutDeclaration === 0 ? 'var(--v-lowMedium-base)' : 'var(--v-lowDark-base)',
          ],
          name: 'cedidoSemDeclaracao',
          status: cloneDeep(state.isCardThreeDisabled),
        },
      ];

      commit('SET_CARDGRAPH_DATA', types);
      commit('SET_GRAPHLOADING', false);
    },
    updateCardGraph({ state, commit }) {
      let graphs = cloneDeep(state.cardGraphData);
      graphs[0].status = cloneDeep(state.isCardOneDisabled);
      graphs[1].status = cloneDeep(state.isCardTwoDisabled);
      graphs[2].status = cloneDeep(state.isCardThreeDisabled);

      commit('SET_CARDGRAPH_DATA', graphs);
    },
    applyFilters({ commit, dispatch }, payload) {
      commit('SET_FILTERS_SAVED', payload);
      dispatch('getDashboard', payload);
    },
    checkFilterForCard({ dispatch }, payload) {
      let status = [];
      if (payload === true) {
        status = [false, true, false];
      } else if (payload === false) {
        status = [false, false, true];
      } else if (payload === null) {
        status = [true, false, false];
      }
      dispatch('changeGraphStatus', status);
      dispatch('updateCardGraph');
    },
    fetchCedidosCurrentDate({ dispatch, commit }, payload) {
      commit('SET_GRAPHLOADING', true);
      commit('SET_LOADING_COLABORADORES_LIST', true);

      let localFilter = filtersDefaultState();

      if (payload === true) {
        localFilter.isCededOnCurrentDate = payload;
      } else {
        localFilter.isCededOnCurrentDate = false;
      }

      dispatch('applyFilters', localFilter);
    },

    clearFilters({ dispatch, commit, state }) {
      commit('SET_FILTERS_SAVED', filtersDefaultState());
      let filters = cloneDeep(state.filtersSaved);
      // let status = [true, false, false];
      // dispatch('changeGraphStatus', status);
      // dispatch('updateCardGraph');

      dispatch('getDashboard', filters);
    },

    filterFromTable({ commit, state, dispatch }, payload) {
      commit('SET_LOADING_COLABORADORES_LIST', true);
      let filters = cloneDeep(state.filtersSaved);
      filters.filterTerm = payload;
      commit('SET_FILTERS_SAVED', filters);

      dispatch('setPagination', defaultPagination());
    },
    async fetchTableData({ commit, state }) {
      commit('SET_LOADING_COLABORADORES_LIST', true);
      let filters = cloneDeep(state.filtersSaved);

      let paginationObject = state.pagination;
      let pagination = definePaginationQuery(paginationObject);
      let requestData = mapFiltersDataForRequest(filters);

      try {
        const { data } = await eSocialService.cedidos.getTable(requestData, pagination);

        data.content.forEach((item, index) => {
          item.id_column = index + 1;
        });

        commit('SET_COLABORADORES_LIST', data.content);
        let totalElements = data.totalElements;

        commit('SET_TOTAL_COLABORADORES', totalElements);
      } catch (e) {
        commit('SET_COLABORADORES_LIST', []);
        commit('SET_TOTAL_COLABORADORES', 0);

        console.error('Error at get collaborators data:', e);
      } finally {
        commit('SET_LOADING_COLABORADORES_LIST', false);
      }
    },

    fetchFromCard({ dispatch, commit }, payload) {
      commit('SET_LOADING_COLABORADORES_LIST', true);
      if (payload === 'totCessao') {
        dispatch('fetchFromCardAll');
      } else if (payload === 'cedidoComDeclaracao') {
        dispatch('fetchFromCardWithDeclaration');
      } else if (payload === 'cedidoSemDeclaracao') {
        dispatch('fetchFromCardWithoutDeclaration');
      }
    },
    fetchFromCardAll({ dispatch, state }) {
      let status = [true, false, false];

      if (state.isCardOneDisabled) {
        status[0] = false;
      }

      dispatch('changeGraphStatus', status);
      dispatch('fetchFromCardWithParam', null);
    },
    fetchFromCardWithDeclaration({ dispatch, state }) {
      let status = [false, true, false];
      let param = true;

      if (state.isCardTwoDisabled) {
        status[1] = false;
        param = null;
      }

      dispatch('changeGraphStatus', status);
      dispatch('fetchFromCardWithParam', param);
    },
    fetchFromCardWithoutDeclaration({ dispatch, state }) {
      let status = [false, false, true];
      let param = false;

      if (state.isCardThreeDisabled) {
        status[2] = false;
        param = null;
      }

      dispatch('changeGraphStatus', status);
      dispatch('fetchFromCardWithParam', param);
    },
    fetchFromCardWithParam({ dispatch, commit, state }, originRemunDeclaration) {
      let filters = cloneDeep(state.filtersSaved);
      filters.originRemunDeclarationToggle = originRemunDeclaration;
      commit('SET_FILTERS_SAVED', filters);

      commit('SET_PAGINATION', defaultPagination());
      dispatch('fetchTableData');
    },
    async exportCollaborators({ commit, state }) {
      let filters = cloneDeep(state.filtersSaved);
      let requestData = mapFiltersDataForRequest(filters);
      let responseDash = await eSocialService.cedidos.export(requestData);
      commit('SET_RESULT_SCHEDULE_REPORT', responseDash.status === 202);
    },
    changeGraphStatus({ commit, dispatch }, payload) {
      dispatch('updateCardGraph');
      commit('SET_CHARTONE_STATUS', payload[0]);
      commit('SET_CHARTTWO_STATUS', payload[1]);
      commit('SET_CHARTTHREE_STATUS', payload[2]);
    },
  },
  mutations: {
    SET_CARDGRAPH_DATA(state, payload) {
      state.cardGraphData = payload;
    },
    SET_BARGRAPH_DATA(state, payload) {
      state.graphData = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReportStatus = payload;
    },
    SET_COLABORADORES_LIST(state, payload) {
      state.colaboradoresList = payload;
    },
    SET_TOTAL_COLABORADORES(state, payload) {
      state.totalColaboradores = payload;
    },
    SET_FILTERS_SAVED(state, payload) {
      state.filtersSaved = cloneDeep(payload);
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_COUNT(state, payload) {
      state.count = payload;
    },
    SET_COUNT_IS_NOT_CEDED(state, payload) {
      state.countIsNotCeded = payload;
    },
    SET_COUNT_CEDED(state, payload) {
      state.countCeded = payload;
    },
    SET_GRAPHLOADING(state, payload) {
      state.loadingGraphs = payload;
    },
    SET_FILTER_LOADING(state, payload) {
      state.loadingFilters = payload;
    },
    SET_CHARTONE_STATUS(state, payload) {
      state.isCardOneDisabled = payload;
    },
    SET_CHARTTWO_STATUS(state, payload) {
      state.isCardTwoDisabled = payload;
    },
    SET_CHARTTHREE_STATUS(state, payload) {
      state.isCardThreeDisabled = payload;
    },
    SET_SELECT_REGPREV(state, payload) {
      state.selectRegPrev = payload;
    },
    SET_SELECT_ORIGINCNPJLIST(state, payload) {
      state.selectOriginCnpjList = payload.map((item) => ({
        ...item,
        itemTitle: item.itemTitle ?? cnpj(item.itemId),
        subtitle: cnpj(item.itemId),
      }));
    },
    SET_SELECT_DESTINATIONCNPJLIST(state, payload) {
      state.selectDestinationCnpjList = payload.map((item) => ({
        ...item,
        itemTitle: item.itemTitle ?? cnpj(item.itemId),
        subtitle: cnpj(item.itemId),
      }));
    },
    SET_LOADING_COLABORADORES_LIST(state, payload) {
      state.loadingColaboradoresList = payload;
    },
  },
};
