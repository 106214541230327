import adminServices from '@/services/adminServices.js';
import { definePaginationQuery } from '@/utils/pagination';
function paginationDefaultState() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}

export default {
  namespaced: true,

  state: {
    employers: [],
    loadingEmployers: false,
    pagination: paginationDefaultState(),
    totalEmployer: 0,
  },

  getters: {
    getEmployers(state) {
      return state.employers;
    },
    getLoadingEmployers(state) {
      return state.loadingEmployers;
    },
    getPagination(state) {
      return state.pagination;
    },
    getTotalEmployer(state) {
      return state.totalEmployer;
    },
  },

  actions: {
    async fetchEmployers({ commit, state }) {
      try {
        commit('SET_EMPLOYERS_LOADING', true);
        let pagination = definePaginationQuery(state.pagination);
        const response = await adminServices.employer.getAll(pagination);
        commit('SET_TOTAL_EMPLOYER', response.data.totalElements);
        response.data.content.forEach((item, index) => {
          item.id_column = index + 1;
        });
        commit('SET_EMPLOYERS', response.data.content);
      } catch (error) {
        console.error('Error fetching employers list: ', error);
      } finally {
        commit('SET_EMPLOYERS_LOADING', false);
      }
    },
    setPagination({ commit, dispatch }, payload) {
      commit('SET_PAGINATION', payload);
      dispatch('fetchEmployers');
    },
  },

  mutations: {
    SET_EMPLOYERS(state, payload) {
      state.employers = payload;
    },
    SET_EMPLOYERS_LOADING(state, payload) {
      state.loadingEmployers = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_TOTAL_EMPLOYER(state, payload) {
      state.totalEmployer = payload;
    },
  },
};
