function defaultPaginationState() {
    return {
        itemsPerPage: 50,
        page: 1,
        sortBy: []
    }
}

export default {
    namespaced: true,
  
    state: {
        rubricNaturePagination: defaultPaginationState(),
        incidenceInssPagination: defaultPaginationState(),
        incidenceIrrfPagination: defaultPaginationState(),
        incidenceNaturePagination: defaultPaginationState()
    },
    getters: {
        getRubricNaturePagination(state) {
            return state.rubricNaturePagination;
        },
        getIncidenceInssPagination(state) {
            return state.incidenceInssPagination;
        },
        getIncidenceIrrfPagination(state) {
            return state.incidenceIrrfPagination;
        },
        getIncidenceNaturePagination(state) {
            return state.incidenceNaturePagination;
        }
    },
    actions: {
        setRubricNaturePagination({ commit }, payload) {
            commit('SET_RUBRIC_NATURE_PAGINATION', payload);
        },
        setIncidenceInssPagination({ commit }, payload) {
            commit('SET_INCIDENCE_INSS_PAGINATION', payload);
        },
        setIncidenceIrrfPagination({ commit }, payload) {
            commit('SET_INCIDENCE_IRRF_PAGINATION', payload);
        },
        setIncidenceNaturePagination({ commit }, payload) {
            commit('SET_INCIDENCE_NATURE_PAGINATION', payload);
        },
    },
    mutations: {
        SET_RUBRIC_NATURE_PAGINATION (state, payload) {
            state.rubricNaturePagination = payload;
        },
        SET_INCIDENCE_INSS_PAGINATION (state, payload) {
            state.incidenceInssPagination = payload;
        },
        SET_INCIDENCE_IRRF_PAGINATION (state, payload) {
            state.incidenceIrrfPagination = payload;
        },
        SET_INCIDENCE_NATURE_PAGINATION (state, payload) {
            state.incidenceNaturePagination = payload;
        },
    }
}