import eSocialService from '@/services/eSocialService.js';
import { definePaginationQuery, isSamePagination } from '@/utils/pagination';
import { cloneDeep } from 'lodash';

function defaultPagination() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}
function mapFiltersDataForRequest(filters) {
  let requestData = {};

  if (![undefined, null].includes(filters)) {
    requestData.familyIds = getOnlyItemIdsArray(filters.family);
    requestData.occupationIds = getOnlyItemIdsArray(filters.occupation);
    requestData.educationIds = getOnlyItemIdsArray(filters.education);
    requestData.professionalRegulatoryBodyIds = getOnlyItemIdsArray(filters.professionalRegulatoryBody);
    requestData.isApprenticeshipQuota = filters.isApprenticeshipQuota;
    requestData.validityRange = [filters.startValidity, filters.endValidity];
    requestData.updatedAt = filters.updated;
    requestData.updatedBy = getOnlyItemIdsArray(filters.amendedBy);
    requestData.titleSynonymsFamily = filters.titleSynonymsFamily;
    if (![undefined, null].includes(filters.titleSynonymsFamily)) {
      requestData.titleSynonymsFamily = filters.titleSynonymsFamily + '$lk$$nml$';
    }
  }

  return requestData;
}
function getOnlyItemIdsArray(items) {
  let ids = [];
  items.forEach((item) => {
    if (![undefined, null].includes(item.itemId)) {
      ids.push(item.itemId);
    }
  });
  return ids;
}

function filtersDefaultState() {
  return {
    family: [],
    occupation: [],
    education: [],
    isApprenticeshipQuota: false,
    isNotApprenticeshipQuota: false,
    professionalRegulatoryBody: [],
    updated: [],
    amendedBy: [],
    titleSynonymsFamily: null,
    startValidity: '',
    endValidity: '',
  };
}

export default {
  namespaced: true,

  state: {
    filtersSaved: filtersDefaultState(),
    loadingCboClassification: true,
    loadingFilters: true,
    cboClassificationList: [],
    filtersSelect: [],
    cboClassificationPagination: { itemsPerPage: 50, page: 1, sortBy: [] },
    scheduleReport: false,
    totalCboClassification: 0,
  },
  getters: {
    getFiltersSelect(state) {
      return state.filtersSelect;
    },
    getDefaultFilterState() {
      return filtersDefaultState();
    },
    getFiltersSaved(state) {
      return state.filtersSaved;
    },
    getCboClassificationList(state) {
      return state.cboClassificationList;
    },
    getCboClassificationPagination(state) {
      return state.cboClassificationPagination;
    },
    getLoadingCboClassificationList(state) {
      return state.loadingCboClassification;
    },
    getCboClassificationTotal(state) {
      return state.totalCboClassification;
    },
    getScheduleReport(state) {
      return state.scheduleReport;
    },
  },

  actions: {
    fetchTableByTerm({ dispatch, state, commit }, payload) {
      let filters = cloneDeep(state.filtersSaved);
      filters.titleSynonymsFamily = payload;
      commit('SET_FILTERS_SAVED', filters);

      dispatch('setCboClassificationPagination', defaultPagination());
    },

    clearFilters({ dispatch, commit, state }) {
      let term = state.filtersSaved.titleSynonymsFamily;
      let filters = filtersDefaultState();
      filters.titleSynonymsFamily = term;
      commit('SET_FILTERS_SAVED', filters);
      dispatch('getCboList');
    },
    async getCboList({ commit, state }) {
      let filters = cloneDeep(state.filtersSaved);

      if (filters.isApprenticeshipQuota === true) {
        delete filters.isNotApprenticeshipQuota;
      } else if (filters.isNotApprenticeshipQuota === true) {
        filters.isApprenticeshipQuota = false;
        delete filters.isNotApprenticeshipQuota;
      } else if (filters.isApprenticeshipQuota === false && filters.isNotApprenticeshipQuota === false) {
        filters.isApprenticeshipQuota = null;
        delete filters.isNotApprenticeshipQuota;
      }

      let requestData = mapFiltersDataForRequest(filters);
      let paginationObject = state.cboClassificationPagination;
      let pagination = definePaginationQuery(paginationObject);

      commit('SET_LOADING_CBO_CLASSIFICATION_LIST', true);

      try {
        const { data } = await eSocialService.cboClassification.getCboList(requestData, pagination);
        data.content.forEach((item, index) => {
          item.id_column = index + 1;
        });
        commit('SET_CBO_CLASSIFICATION_LIST', data.content);
        let totalElements = data.totalElements;
        commit('SET_TOTAL_CBO_CLASSIFICATION_ITEMS', totalElements);
        commit('SET_LOADING_CBO_CLASSIFICATION_LIST', false);
      } catch (error) {
        commit('SET_CBO_CLASSIFICATION_LIST', []);
        commit('SET_TOTAL_CBO_CLASSIFICATION_ITEMS', 0);
        commit('SET_LOADING_CBO_CLASSIFICATION_LIST', false);

        console.error('AG_ERROR Error at cbo List:', error);
      }
    },
    async fetchSelectFilters({ dispatch, commit }) {
      const response = await eSocialService.cboClassification.getCboFilters();
      let filters = response.data;
      if (!filters.groupAmendedBy) {
        filters.groupAmendedBy = filter.groupAmendedBy;
      }
      commit('SET_FILTERS_SELECTS', filters);

      dispatch('getCboList');
    },
    applyFilters({ dispatch, commit, state }, payload) {
      payload.titleSynonymsFamily = state.filtersSaved.titleSynonymsFamily;
      commit('SET_FILTERS_SAVED', payload);
      dispatch('getCboList');
    },

    async exportCboClassifications({ commit, state }) {
      let filters = cloneDeep(state.filtersSaved);

      if (filters.isApprenticeshipQuota === true) {
        delete filters.isApprenticeshipQuota;
      } else if (filters.isNotApprenticeshipQuota === true) {
        filters.isApprenticeshipQuota = false;
        delete filters.isNotApprenticeshipQuota;
      } else if (filters.isApprenticeshipQuota === false && filters.isNotApprenticeshipQuota === false) {
        filters.isApprenticeshipQuota = null;
        delete filters.isNotApprenticeshipQuota;
      }

      let requestData = mapFiltersDataForRequest(filters);
      let responseDash = await eSocialService.cboClassification.exportCboList(requestData);
      commit('SET_RESULT_SCHEDULE_REPORT', responseDash.status === 202);
    },
    setCboClassificationPagination({ commit, state, dispatch }, payload) {
      let isSame = isSamePagination(payload, state.cboClassificationPagination);

      commit('SET_INCIDENCE_CBO_CLASSIFICATION_PAGINATION', payload);
      if (!isSame) {
        dispatch('getCboList', '');
      }
    },
  },

  mutations: {
    SET_INCIDENCE_CBO_CLASSIFICATION_PAGINATION(state, payload) {
      state.cboClassificationPagination = payload;
    },
    SET_LOADING_CBO_CLASSIFICATION_LIST(state, payload) {
      state.loadingCboClassification = payload;
    },
    SET_FILTERS_SAVED(state, payload) {
      state.filtersSaved = cloneDeep(payload);
    },
    SET_CBO_CLASSIFICATION_LIST(state, payload) {
      state.cboClassificationList = payload;
    },
    SET_TOTAL_CBO_CLASSIFICATION_ITEMS(state, payload) {
      state.totalCboClassification = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReport = payload;
    },
    SET_FILTERS_SELECTS(state, payload) {
      state.filtersSelect = payload;
    },
  },
};
