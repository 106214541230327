import DescCodigos from '@/assets/json/descCodigos.json';
import eSocialService from '@/services/eSocialService.js';
import { definePaginationQuery, isSamePagination } from '@/utils/pagination';
import UtilsDate from '@/utils/UtilsDate';
import { cloneDeep, debounce } from 'lodash';

function defaultPagination() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}
export default {
  namespaced: true,

  state: {
    pagination: {
      itemsPerPage: 50,
      page: 1,
      sort: [],
    },
    tab: 'todos',
    title: 'Todos',
    dataOrNot: { hasData: true, isFromCard: false, name: '' },
    params: {},
    situationForCards: [],
    totalForSituation: [],
    situationOverview: [],
    dscRubrDadosrubrica: '',
    iniValidIderubrica: '',
    fimValidIderubrica: '',
    status: [],
    nrInscIdeempregador: [],
    dates: [],
    active: false,
    loadingData: false,
    rubricas: [],
    totalRubricas: 0,
    situationColors: [],
    motivosAfastamento: [],
    loadingRubricGraphs: true,
  },
  getters: {
    getSituationColors(state) {
      return state.situationColors;
    },
    getStatus(state) {
      return state.status;
    },
    pagination(state) {
      return state.pagination;
    },

    getTab(state) {
      return state.tab;
    },
    getTitle(state) {
      return state.title;
    },
    getDataOrNot(state) {
      return state.dataOrNot;
    },
    getDates(state) {
      return state.dates;
    },
    dscRubrDadosrubrica(state) {
      return state.dscRubrDadosrubrica;
    },
    getStatusFilter(state) {
      return state.status;
    },
    iniValidIderubrica(state) {
      return state.iniValidIderubrica;
    },
    fimValidIderubrica(state) {
      return state.fimValidIderubrica;
    },
    getNrInscIdeempregador(state) {
      return state.nrInscIdeempregador;
    },
    getParams(state) {
      return state.params;
    },
    getLoading(state) {
      return state.loadingData;
    },
    getLoadingRubricGraphs(state) {
      return state.loadingRubricGraphs;
    },
    getTotalRubricas(state) {
      return state.totalRubricas;
    },
    getRubricas(state) {
      return state.rubricas;
    },
    getTotalForSituation(state) {
      return state.totalForSituation;
    },
    getSituationOverview(state) {
      return state.situationOverview;
    },
    getSituationForCards(state) {
      return state.situationForCards;
    },
    getMotivosAfastamento(state) {
      return state.motivosAfastamento;
    },
  },

  actions: {
    cleanPersistentFilter({ commit, dispatch }) {
      commit('SET_LOADING', true);
      const params = {};
      commit('SET_PARAMS', params);
      commit('SET_FILTER', params);
      commit('SET_DESCRUBRDADOSRUBRICA', '');
      commit('SET_FIMVALIDIDERUBRICA', '');
      commit('SET_INIVALIDIDERUBRICA', '');
      commit('SET_NRINSCIDEEMPREGADOR', []);
      commit('SET_STATUSFILTER', []);
      commit('SET_DATES', []);
      commit('SET_ISFROMCARD', false);
      dispatch('fetchDebounce');
    },
    setTab({ commit }, payload) {
      commit('SET_TAB', payload);
    },
    setTitle({ commit }, payload) {
      commit('SET_TITLE', payload);
    },
    changeFilterRubricas({ commit }, { prop, value }) {
      commit('SET_ISFROMCARD', false);
      if (prop === 'nrInscIdeempregador') {
        commit('SET_NRINSCIDEEMPREGADOR', '');
        commit('SET_NRINSCIDEEMPREGADOR', value);
      }
      if (prop === 'dates') {
        commit('SET_DATES', '');
        commit('SET_DATES', value);
        commit('SET_INIVALIDIDERUBRICA', '');
        commit('SET_INIVALIDIDERUBRICA', value[0]);
        commit('SET_FIMVALIDIDERUBRICA', '');
        commit('SET_FIMVALIDIDERUBRICA', value[1]);
      }
      if (prop === 'status') {
        commit('SET_STATUSFILTER', '');
        commit('SET_STATUSFILTER', value);
      }
      if (prop === 'dscRubrDadosrubrica') {
        commit('SET_DESCRUBRDADOSRUBRICA', value);
      }
    },
    fetchRubricas({ commit, dispatch, state }) {
      commit('SET_LOADING', true);
      const temp = {};

      if (state.nrInscIdeempregador?.length > 0) {
        temp.nrInscIdeempregador = state.nrInscIdeempregador.map((item) => {
          return item.subtitle?.replaceAll('.', '').substring(0, 8);
        });
      }

      if (state.iniValidIderubrica?.length > 0) {
        temp.iniValidIderubrica = state.iniValidIderubrica;
      }
      if (state.fimValidIderubrica?.length > 0) {
        temp.fimValidIderubrica = state.fimValidIderubrica;
      }
      if (state.status?.length > 0) {
        temp.status = state.status;
      }
      if (state.dscRubrDadosrubrica?.length > 0) {
        temp.dscRubrDadosrubrica = state.dscRubrDadosrubrica;
      }

      commit('SET_PARAMS', temp);
      commit('SET_FILTER', temp);
      dispatch('fetchDebounce');
    },

    changeFilter({ commit, dispatch }, { prop, value }) {
      if (prop === 'dscRubrDadosrubrica') {
        commit('SET_DESCRUBRDADOSRUBRICA', value);
      }
      commit('SET_ISFROMCARD', false);
      dispatch('setPagination', defaultPagination());
    },

    fetchFromCard({ commit, dispatch }, { prop, value }) {
      let stt = [];
      commit('SET_ISFROMCARD', true);
      commit('SET_ISFROMCARDNAME', value);

      const name = {
        'Todas as rubricas': null,
        'Pendente de validação': 'PENDENTE_DE_VALIDACAO',
        Conciliada: 'CONCILIADA',
        'Pendente de aprovação': 'PENDENTE_APROVACAO',
        'Pendente de parametrização': 'PENDENTE_PARAMETRIZACAO',
        Divergente: 'DIVERGENTE',
        Reprovada: 'REPROVADA',
      }[value];
      if (name != null) {
        stt.push(name);
      }
      commit('SET_STATUSFILTER', '');
      if (prop === 'status' && name != null) {
        commit('SET_STATUSFILTER', stt);
      }
      dispatch('fetchRubricas');
    },

    clearProp({ commit, state }, { prop }) {
      commit('SET_LOADING', false);
      if (prop === '') {
        return;
      }
      if (prop === 'iniValidIderubrica') {
        commit('SET_INIVALIDIDERUBRICA', '');
      }
      if (prop === 'fimValidIderubrica') {
        commit('SET_FIMVALIDIDERUBRICA');
      }
      if (prop === 'nrInscIdeempregador') {
        commit('SET_NRINSCIDEEMPREGADOR');
      }
      if (prop === 'dscRubrDadosrubrica') {
        commit('SET_DESCRUBRDADOSRUBRICA', '');
      }
      if (prop === 'status') {
        commit('SET_STATUSFILTER', '');
      }

      const params = cloneDeep(state.params);

      commit('SET_FILTER', params);
    },

    fetchDebounce: debounce(({ commit, dispatch }) => {
      commit('SET_LOADING', true);
      return dispatch('fetch');
    }, 500),

    async fetch({ commit, state, dispatch }) {
      try {
        let paginationObject = state.pagination;
        let pagination = definePaginationQuery(paginationObject);
        // let paginationParams = { page: page - 1, size: itemsPerPage };

        // if (state.paginationParams && state.paginationParams.sortBy && state.paginationParams.sortBy.length > 0) {
        //   let field = state.paginationParams.sortBy[0];
        //   let direction = state.paginationParams.sortDesc[0] ? 'asc' : 'desc';
        //   let sort = field + ':' + direction;

        //   paginationParams.sort = sort;
        // }

        // let pagination = new URLSearchParams(paginationParams).toString();

        commit('SET_LOADING_RUBRIC_GRAPHS', true);

        let response = await eSocialService.evttabrubrica.search(pagination, state.params);

        let listado = [];
        let keys = [];

        // todo remove validation on line 262 when fixed back-end
        Object.entries(response.data.data.content).forEach(([key, entries]) => {
          keys.push(key);

          let dateTime = new Date(entries.dataRegistro);
          let date = UtilsDate.format(dateTime, 'L');

          entries.dataRegistro = date;

          listado.push({
            id: entries.id,
            dscRubrDadosrubrica: entries.dscRubrDadosrubrica,
            idDocumento: entries.idDocumento,
            natRubrDadosrubrica: DescCodigos.natRubr[entries.natRubrDadosrubrica],
            codRubrIderubrica: entries.codRubrIderubrica,
            nrInscIdeempregador: entries.nrInscIdeempregador,
            iniValidIderubrica: entries.iniValidIderubrica,
            fimValidIderubrica: entries.fimValidIderubrica === 'null' ? '' : entries.fimValidIderubrica,
            dataRegistro: entries.dataRegistro,
            ideTabRubrIderubrica: entries.ideTabRubrIderubrica,
            tpRubrDadosrubrica: DescCodigos.tpRubr[entries.tpRubrDadosrubrica],
            codIncCPDadosrubrica: DescCodigos.codIncCP[entries.codIncCPDadosrubrica],
            codIncIRRFDadosrubrica: DescCodigos.codIncIR[entries.codIncIRRFDadosrubrica],
            codIncFGTSDadosrubrica: DescCodigos.codIncFGTS[entries.codIncFGTSDadosrubrica],
            observacaoDadosrubrica: entries.observacaoDadosrubrica,
            listEvtTabRubricaIdeProcessoCP: entries.listEvtTabRubricaIdeProcessoCP,
            listEvtTabRubricaIdeProcessoIRRF: entries.listEvtTabRubricaIdeProcessoIRRF,
            listEvtTabRubricaIdeProcessoFGTS: entries.listEvtTabRubricaIdeProcessoFGTS,
            operacao: entries.operacao,
            status: entries.status,
            companyName: entries.companyName,
          });
        });

        if (listado.length > 0 || listado.length == 0) {
          commit('SET_LOADING', false);
        }
        commit('SET_TOTAL_RUBRICAS', response.data.data.totalElements);
        // todo remove validation on line 302 and 203 when fixed back-end
        dispatch('extractTotalForSituation', response.data.counters.counters);
        dispatch('extractSituationOverview', response.data.counters.counters);

        commit('SET_RUBRICAS', listado);
        if (listado.length > 0) {
          commit('SET_HASDATAORNOT', true);
          commit('SET_LOADING', false);
        } else {
          commit('SET_HASDATAORNOT', false);
          commit('SET_LOADING', false);
        }
      } catch (e) {
        console.error(e);
      }
    },
    extractSituationOverview({ commit }, counters) {
      let conciliadas = 0;
      let others = 0;
      counters.map((element) => {
        if (element.status === 'CONCILIADA') {
          conciliadas = conciliadas + element.details.total;
        } else {
          others = others + element.details.total;
        }
      });
      let series = [others, conciliadas];

      commit('SET_SITUATION_OVERVIEW', series);
    },
    extractTotalForSituation({ commit }, counters) {
      let seriesFilled = [{ data: [] }];

      let keys = [];
      let listOfStatus = [
        {
          name: 'Todas as rubricas',
          total: [],
          percent: 100,
          color: '#2A1C4F',
          tooltipT: 'Conciliada, Pendente de parametrização, Pendente de aprovação e Divergente',
        },
      ];
      let totalForStatus = 0;

      Object.entries(counters).forEach(([key, element]) => {
        keys.push(key);
        const name = {
          PENDENTE_DE_VALIDACAO: 'Pendente de validação',
          CONCILIADA: 'Conciliada',
          PENDENTE_APROVACAO: 'Pendente de aprovação',
          PENDENTE_PARAMETRIZACAO: 'Pendente de parametrização',
          DIVERGENTE: 'Divergente',
          REPROVADA: 'Reprovada',
        }[element.status];
        const colorSelected = {
          PENDENTE_DE_VALIDACAO: '#442D96',
          CONCILIADA: '#00FF99',
          PENDENTE_APROVACAO: '#442D96',
          PENDENTE_PARAMETRIZACAO: '#442D96',
          DIVERGENTE: '#442D96',
          REPROVADA: '#442D96',
        }[element.status];
        const tooltipText = {
          PENDENTE_DE_VALIDACAO: 'Rubricas validadas, aguardando integração com o sistema de folha de pagamento',
          CONCILIADA: 'Rubrica ativa, validada, aprovada e parametrizada',
          PENDENTE_APROVACAO:
            'Rubrica aguardando o responsável da empresa autorizar a inclusão e/ou alteração validada',
          PENDENTE_PARAMETRIZACAO: 'Rubricas validadas, aguardando integração com o sistema de folha de pagamento',
          DIVERGENTE:
            'Rubricas que apresentam alguma incongruência em relação aos dados que foram previamente validados e aprovados.',
          REPROVADA:
            'Rubricas que receberam validação Agnes, mas ainda aguardam a aprovação final por parte do responsável autorizado da empresa.',
        }[element.status];

        seriesFilled[0].data.push({
          x: name,
          y: element.details.total,
          color: colorSelected,
        });

        totalForStatus += element.details.total;

        let percent = element.details.porcentagem === 'NaN' ? 0 : element.details.porcentagem;

        listOfStatus.push({
          name: name,
          total: [element.details.total],
          percent: percent.toFixed(2),
          color: colorSelected,
          tooltipT: tooltipText,
        });
      });

      listOfStatus[0].total.push(totalForStatus);
      let toReduce = listOfStatus[0].total[0];

      let updatedListOFStatus = listOfStatus.map((item) => {
        var temp = Object.assign({}, item);

        temp.total.unshift(toReduce - temp.total[0]);
        return temp;
      });

      let colors = [];
      seriesFilled[0].data.map((element) => {
        colors.push(element.color);
      });
      commit('SET_TOTALSITUATION_COLORS', colors);
      commit('SET_SITUATION_FOR_CARDS', updatedListOFStatus);
      commit('SET_TOTAL_FOR_SITUATION', seriesFilled);
      commit('SET_LOADING_RUBRIC_GRAPHS', false);
    },

    setPagination({ dispatch, commit, state }, payload) {
      let isSame = isSamePagination(state.pagination, payload);

      commit('SET_PAGINATION', payload);
      if (!isSame) {
        dispatch('fetchRubricas');
      }
    },
    async fetchMotivosAfastamento({ commit }) {
      let results = [
        {
          codigo: '01',
          descricao: 'Acidente/doença do trabalho',
          inicio: '01/01/2014',
          termino: '',
          codCategoria: 'Todos',
        },
        {
          codigo: '03',
          descricao: 'Acidente/doença não relacionada ao trabalho',
          inicio: '01/01/2014',
          termino: '',
          codCategoria: 'Todos',
        },
      ];
      commit('SET_MOTIVOS_DE_AFASTAMENTO', results);
    },

    setNrInscIdeempregador({ commit }, payload) {
      commit('SET_NRINSCIDEEMPREGADOR', payload);
    },

    setStatusFilter({ commit }, payload) {
      commit('SET_STATUSFILTER', payload);
    },

    setDatesFilter({ commit }, payload) {
      commit('SET_DATES', payload);
    },
  },

  mutations: {
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_TAB(state, payload) {
      state.tab = payload;
    },
    SET_TITLE(state, payload) {
      state.title = payload;
    },

    SET_FILTER(state, payload) {
      state.params = payload;
    },
    SET_ISFROMCARD(state, payload) {
      state.dataOrNot.isFromCard = payload;
    },
    SET_HASDATAORNOT(state, payload) {
      state.dataOrNot.hasData = payload;
    },
    SET_ISFROMCARDNAME(state, payload) {
      state.dataOrNot.name = payload;
    },
    SET_STATUSFILTER(state, payload) {
      state.status = payload;
    },
    SET_DATES(state, payload) {
      state.dates = payload;
    },
    SET_PARAMS(state, payload) {
      state.params = payload;
    },
    SET_ACTIVE(state, payload) {
      state.active = payload;
    },
    SET_LOADING(state, payload) {
      state.loadingData = payload;
    },
    SET_DATAORNOT(state, payload) {
      state.dataOrNot = payload;
    },
    SET_RUBRICAS(state, payload) {
      state.rubricas = payload;
    },
    SET_TOTALSITUATION_COLORS(state, payload) {
      state.situationColors = payload;
    },
    SET_TOTAL_RUBRICAS(state, payload) {
      state.totalRubricas = payload;
    },
    SET_DESCRUBRDADOSRUBRICA(state, payload) {
      state.dscRubrDadosrubrica = payload;
    },
    SET_DATESFORMATED(state, payload) {
      state.datesFormated = payload;
    },
    SET_INIVALIDIDERUBRICA(state, payload) {
      state.iniValidIderubrica = payload;
    },
    SET_FIMVALIDIDERUBRICA(state, payload) {
      state.fimValidIderubrica = payload;
    },
    SET_NRINSCIDEEMPREGADOR(state, payload) {
      state.nrInscIdeempregador = payload;
    },
    SET_TOTAL_FOR_SITUATION(state, payload) {
      state.totalForSituation = payload;
    },
    SET_SITUATION_OVERVIEW(state, payload) {
      state.situationOverview = payload;
    },
    SET_SITUATION_FOR_CARDS(state, payload) {
      state.situationForCards = payload;
    },
    SET_MOTIVOS_DE_AFASTAMENTO(state, payload) {
      state.motivosAfastamento = payload;
    },
    SET_LOADING_RUBRIC_GRAPHS(state, payload) {
      state.loadingRubricGraphs = payload;
    },
  },
};
