import eSocialService from '@/services/eSocialService.js';
import { definePaginationQuery, isSamePagination } from '@/utils/pagination';
import { cloneDeep } from 'lodash';

function mapFiltersDataForRequest(filters) {
  let requestData = {};

  if (filters) {
    requestData.cnpjs = filters.cnpjs;
    requestData.filterTerm = filters.filterTerm ? filters.filterTerm.toUpperCase() : '';
  }

  return requestData;
}

function paginationDefaultState() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}

function filtersDefaultState() {
  return {
    cnpjs: [],
  };
}

export default {
  namespaced: true,
  state: {
    filtersSaved: filtersDefaultState(),
    loadingHorariosEJornadas: true,
    horariosEJornadasList: [],
    horariosEJornadasPagination: paginationDefaultState(),
    scheduleReport: false,
    totalHorariosEJornadas: 0,
    filterTerm: '',
  },
  getters: {
    getDefaultFilterState() {
      return filtersDefaultState();
    },
    getFiltersSaved(state) {
      return state.filtersSaved;
    },
    getHorariosEJornadasList(state) {
      return state.horariosEJornadasList;
    },
    getHorariosEJornadasPagination(state) {
      return state.horariosEJornadasPagination;
    },
    getLoadingHorariosEJornadasList(state) {
      return state.loadingHorariosEJornadas;
    },
    getHorariosEJornadasTotal(state) {
      return state.totalHorariosEJornadas;
    },
    getScheduleReport(state) {
      return state.scheduleReport;
    },
  },

  actions: {
    init({ dispatch }) {
      dispatch('fetchHorariosEJornadasList');
    },
    fetchTableByTerm({ dispatch, state, commit }, payload) {
      let filters = cloneDeep(state.filtersSaved);
      filters.filterTerm = payload;
      commit('SET_FILTERS_SAVED', filters);
      dispatch('setHorariosEJornadasPagination', paginationDefaultState());
    },
    clearFilters({ dispatch, commit, state }) {
      let term = state.filtersSaved.filterTerm;
      let filters = filtersDefaultState();
      filters.filterTerm = term;
      commit('SET_FILTERS_SAVED', filters);
      dispatch('fetchHorariosEJornadasList');
    },
    async fetchHorariosEJornadasList({ commit, state }) {
      let filters = cloneDeep(state.filtersSaved);
      let requestData = mapFiltersDataForRequest(filters);
      let paginationObject = state.horariosEJornadasPagination;
      let pagination = definePaginationQuery(paginationObject);

      commit('SET_LOADING_HORARIOS_E_JORNADAS_LIST', true);

      try {
        const { data } = await eSocialService.horarioEJornadas.getHorarioEJornadasList(requestData, pagination);
        data.content.forEach((item, index) => {
          item.id_column = index + 1;
        });
        commit('SET_HORARIOS_E_JORNADAS_LIST', data.content);
        let totalElements = data.totalElements;
        commit('SET_TOTAL_HORARIOS_E_JORNADAS_ITEMS', totalElements);
        commit('SET_LOADING_HORARIOS_E_JORNADAS_LIST', false);
      } catch (error) {
        commit('SET_HORARIOS_E_JORNADAS_LIST', []);
        commit('SET_TOTAL_HORARIOS_E_JORNADAS_ITEMS', 0);
        commit('SET_LOADING_HORARIOS_E_JORNADAS_LIST', false);

        console.error('AG_ERROR Error at horarios e Jornadas List:', error);
      }
    },
    applyFilters({ dispatch, commit, state }, payload) {
      payload.filterTerm = state.filtersSaved.filterTerm;
      commit('SET_FILTERS_SAVED', payload);
      dispatch('fetchHorariosEJornadasList');
    },
    async exportHorariosEJornadas({ commit, state }) {
      let filters = cloneDeep(state.filtersSaved);
      let requestData = mapFiltersDataForRequest(filters);
      let responseDash = await eSocialService.horarioEJornadas.exportHorarioEJornadasList(requestData);
      commit('SET_RESULT_SCHEDULE_REPORT', responseDash.status === 202);
    },
    setHorariosEJornadasPagination({ commit, state, dispatch }, payload) {
      let isSame = isSamePagination(payload, state.horariosEJornadasPagination);

      commit('SET_INCIDENCE_HORARIOS_E_JORNADAS_PAGINATION', payload);

      if (!isSame) {
        dispatch('fetchHorariosEJornadasList');
      }
    },
  },

  mutations: {
    SET_INCIDENCE_HORARIOS_E_JORNADAS_PAGINATION(state, payload) {
      state.horariosEJornadasPagination = payload;
    },
    SET_LOADING_HORARIOS_E_JORNADAS_LIST(state, payload) {
      state.loadingHorariosEJornadas = payload;
    },
    SET_FILTERS_SAVED(state, payload) {
      state.filtersSaved = payload;
    },
    SET_HORARIOS_E_JORNADAS_LIST(state, payload) {
      state.horariosEJornadasList = payload;
    },
    SET_TOTAL_HORARIOS_E_JORNADAS_ITEMS(state, payload) {
      state.totalHorariosEJornadas = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReport = payload;
    },
  },
};
