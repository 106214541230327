function cnpj(value) {
  if (!value) return '';
  value = value.toString();
  if (value.length === 13) {
    value = '0'.concat(value);
  }

  if (value.length === 8) return value.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?/, '$1.$2.$3');
  if (value.length === 11) return value.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4');
  if (value.length === 12) return value.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?/, '$1.$2.$3/$4');
  if (value.length === 14)
    return value.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');
  return value;
}

function cpf(value) {
  if (!value) return '';
  value = value.toString();
  if (value.length === 11) return value.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4');
  return value;
}

module.exports = {
  cnpj,
  cpf,
};
