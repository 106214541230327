import UserService from '@/services/userService.js';
import gedService from '@/services/gedService.js';
import userService from '../../../services/userService';

export default {
  namespaced: true,

  state: {
    loading: true,
    user: {},
    thumbnail: '',
    userRoles: [],
    roles: [],
    alteredMenu: false,
    alteredAdm: false,
    menus: [],
    adminMenus: [],
    newMenus: [],
    status: '',
    newAdminMenus: [],
  },
  getters: {
    getUserThumbnail(state) {
      return state.thumbnail;
    },
    getMenus(state) {
      return state.menus;
    },
    getAdminMenus(state) {
      return state.adminMenus;
    },
    getAlteredMenu(state) {
      return state.alteredMenu;
    },
    getAlteredAdm(state) {
      return state.alteredAdm;
    },
    getstatus(state) {
      return state.status;
    },
  },

  actions: {
    async setMenus({ commit }, payload) {
      if (payload) {
        commit('SET_NEWMENUS', payload);
        commit('SET_IS_ALTERED_MENU', true);
      }
    },
    async setAdminMenus({ commit }, payload) {
      if (payload) {
        commit('SET_NEW_ADMIN_MENUS', payload);
        commit('SET_IS_ALTERED_ADM', true);
      }
    },
    setAlteredAdm({ commit }) {
      commit('SET_IS_ALTERED_ADM', true);
    },
    async saveNewMenus({ state, dispatch, commit }, payload) {
      let newMenus = {};

      if (state.alteredMenu === true && state.alteredAdm === true) {
        newMenus = { layoutMenu: state.newMenus, adminMenus: JSON.stringify(state.adminMenus) };
      } else if (state.alteredMenu === true && state.alteredAdm === false) {
        newMenus = { layoutMenu: state.newMenus, adminMenus: JSON.stringify(state.adminMenus) };
      } else if (state.alteredMenu === false && state.alteredAdm === true) {
        newMenus = { layoutMenu: JSON.stringify(state.menus), adminMenus: JSON.stringify(state.adminMenus) };
      }
      let res = await UserService.updateOtherUserMenu(payload, newMenus);
      if (res.status === 200) {
        commit('SET_IS_ALTERED_ADM', false);
        commit('SET_IS_ALTERED_MENU', false);
        dispatch('fetchUser', payload);
        commit('SET_STATUS', res.status);
        // dispatch('cadastrosGerenciais/usuario/fetchUser', payload.id, { root: true });
      }
    },
    async saveSameMenus({ commit, dispatch }, payload) {
      let res = await UserService.updateOtherUserMenu(payload.id, payload.menus);
      if (res.status === 200) {
        commit('SET_IS_ALTERED_ADM', false);
        commit('SET_IS_ALTERED_MENU', false);
        commit('SET_STATUS', res.status);
        // dispatch('cadastrosGerenciais/usuario/fetchUser', payload.id, { root: true });
      }
    },
    async resetScreens({ dispatch, commit }, payload) {
      let res = await UserService.updateOtherUserMenu(payload.id, payload.menus);
      if (res.status === 200) {
        commit('SET_IS_ALTERED_ADM', false);
        commit('SET_IS_ALTERED_MENU', false);
        commit('SET_STATUS', res.status);
        dispatch('fetchUser', payload.id);
      }
    },
    async fetchUser({ dispatch, commit }, id) {
      commit('SET_LOADING', true);
      commit('SET_USER', {});
      const { data } = await userService.get(id);

      commit('SET_USER', data);
      commit('SET_USERROLES', data.authorities);
      dispatch('getThumbnail');

      commit('SET_LOADING', false);
    },

    async getThumbnail({ commit, state }) {
      let response = await gedService.profile.get(state.user.thumbnailId);
      let thumbnail = 'data:image/png;base64,' + response.data.base64;

      commit('SET_THUMBNAIL', thumbnail);
    },

    async getRoles({ commit, state }) {
      if (state.roles.length > 0) return;

      const { data } = await UserService.role.get();

      var roles = data.reduce((roles, item) => {
        if (item != 'MENU_INTELLIGENCE' && item != 'MENU_SOURCE_DATA') {
          roles.push('ROLE_' + item);
        }
        return roles;
      }, []);

      commit('SET_ROLES', roles);
    },
    async saveRoles({ commit, state, dispatch }, payload) {
      commit('SET_LOADING', true);
      await UserService.updateOtherUser({ id: state.user.id, ...payload });
      dispatch('fetchUser', state.user.id);
    },
  },

  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_ROLES(state, payload) {
      state.roles = payload;
    },
    SET_USERROLES(state, payload) {
      state.userRoles = payload;
    },
    SET_THUMBNAIL(state, payload) {
      state.thumbnail = payload;
    },
    SET_ADMIN_MENUS(state, payload) {
      state.adminMenus = payload;
    },
    SET_MENUS(state, payload) {
      state.menus = payload;
    },
    SET_NEWMENUS(state, payload) {
      state.newMenus = payload;
    },
    SET_NEW_ADMIN_MENUS(state, payload) {
      state.newAdminMenus = payload;
    },
    SET_IS_ALTERED_MENU(state, payload) {
      state.alteredMenu = payload;
    },
    SET_IS_ALTERED_ADM(state, payload) {
      state.alteredAdm = payload;
    },
    SET_STATUS(state, payload) {
      state.status = payload;
    },
  },
};
